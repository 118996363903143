import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { get } from "../../axios/apiCall";
import {
  COMMUNITY_USER_COUNT,
  COMMUNITY_POST_COUNT,
  COMMUNITY_RESOURCE_COUNT,
  COMMUNITY_PODCAST_COUNT,
} from "../../axios/apiUrl";
import { Col, Row } from "reactstrap";

Chart.register(...registerables);

export default function DashboardComponent() {
  const [dataSet, setDataSet] = useState([]);

  useEffect(() => {
    get(COMMUNITY_USER_COUNT, (response) =>
      countSuccess(response, "user", "rgba(255, 99, 132, 0.5)")
    );
    get(COMMUNITY_POST_COUNT, (response) =>
      countSuccess(response, "Post", "rgba(54, 162, 235, 0.5)")
    );
    get(COMMUNITY_RESOURCE_COUNT, (response) =>
      countSuccess(response, "Resource", "rgba(255, 206, 86, 0.5)")
    );
    get(COMMUNITY_PODCAST_COUNT, (response) =>
      countSuccess(response.data, "Podcast", "rgba(75, 192, 192, 0.5)")
    );
    return () => {
      setDataSet([]);
    };
  }, []);

  function countSuccess(response, title, color) {
    const data = {
      label: title,
      data: [
        response.todaysData,
        response.thisMonthData,
        response.activeData,
        response.totalData,
      ],
      backgroundColor: color,
    };
    setDataSet((prevState) => [...prevState, data]);
  }

  return (
    <>
      <Row>
        <Col xl={6} style={{marginBottom: 40}}>
          <Bar
            height={400}
            width={500}
            data={{
              labels: [
                "Today",
                "This Month",
                "Total Active",
                "Total Registered",
              ],
              datasets: dataSet,
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </Col>
        <Col xl={6} style={{marginBottom: 40}}>
          <Line
            height={400}
            width={500}
            data={{
              labels: [
                "Today",
                "This Month",
                "Total Active",
                "Total Registered",
              ],
              datasets: dataSet,
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
}
