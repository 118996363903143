import { post, put } from "axios/apiCall";
import { COMMUNITY_RESOURCE } from "axios/apiUrl";
import ShowImageComponent from "components/common/image/ShowImageComponent";
import LoaderButtonComponent from "components/common/LoaderButtonComponent";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";

function SaveUpdateResource({ setToggle, setPage, resourceData }) {
  const [imageList, setImageList] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (resourceData) {
      setImageList(resourceData.images);
    }
  }, [resourceData]);

  function selectedFile(event) {
    if (event.target.value !== "") {
      document.getElementById(`${event.target.id}Label`).innerHTML = "";
      const files = event.target.files;
      for (let index = 0; index < files.length; index++) {
        document.getElementById(`${event.target.id}Label`).innerHTML +=
          files[index].name;
      }
    }
  }

  function saveUpdateResource(event) {
    event.preventDefault();
    const formData = new FormData();

    const data = event.target;
    const id = data.id.value;

    if (data.zipCode.value === "" || data.zipCode.value.length !== 5) {
      toast.error("Please enter valid zip code", {
        theme: "colored",
      });
      return;
    } else if (data.description.value === "" && data.file.files.length === 0) {
      toast.error("Please Enter Content or Image", {
        theme: "colored",
      });
      return;
    }

    formData.append("id", id);
    formData.append("description", data.description.value);

    for (let index = 0; index < data.file.files.length; index++) {
      formData.append("file", data.file.files[index]);
    }
    formData.append("zipCode", data.zipCode.value);

    if (id !== "") {
      formData.append("images", imageList);
    }
    setLoading(true);
    if (id === "") {
      post(COMMUNITY_RESOURCE, formData, savePostSuccess, savePostError);
    } else {
      put(COMMUNITY_RESOURCE, formData, updatePostSuccess, savePostError);
    }
  }

  function savePostSuccess(response) {
    success(response, "Reshare Successfully Saved");
  }

  function updatePostSuccess(response) {
    success(response, "Reshare Successfully Updated");
  }

  function success(response, message) {
    setLoading(false);
    toast.success(message, {
      theme: "colored",
    });
    setPage(1);
    setPage(0);
    setToggle(false);
  }

  function savePostError(error) {
    setLoading(false);
    toast.error(error.message, {
      theme: "colored",
    });
  }

  return (
    <>
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>{resourceData ? "Update" : "Save"} Resource</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg- py-lg-3">
            {resourceData && resourceData.images && (
              <ShowImageComponent
                imageList={resourceData.images}
                setImageList={setImageList}
              />
            )}
            <Form role="form" onSubmit={saveUpdateResource}>
              <Input
                type="text"
                name="id"
                defaultValue={resourceData ? resourceData.resourceId : ""}
                className="d-none"
              />
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    placeholder="Reshare Content..."
                    name="description"
                    defaultValue={resourceData ? resourceData.description : ""}
                    rows="3"
                    type="textarea"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    placeholder="Zip Code..."
                    name="zipCode"
                    defaultValue={resourceData ? resourceData.zipCode : ""}
                    rows="3"
                    type="input"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <div className="custom-file h-auto">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="image"
                    lang="es"
                    name="file"
                    onChange={selectedFile}
                    multiple
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="image"
                    id="imageLabel"
                  >
                    Select Image
                  </label>
                </div>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="info"
                  type="button"
                  onClick={() => setToggle(false)}
                >
                  Cancel
                </Button>
                <LoaderButtonComponent
                  text="Save"
                  loading={loading}
                  type="submit"
                />
                {/* <Button className="my-4" color="primary" type="submit">
                  Save
                </Button> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SaveUpdateResource;
