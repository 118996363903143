// reactstrap components
import CardCountList from "components/cardCount/CardCountList";
import { Container, Row } from "reactstrap";

const Header = ({ data }) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-md-8"
        style={{ backgroundColor: "#016b4f" }}
      >
        <Container fluid>
          <div className="header-body">
            <Row>
              <CardCountList data={data} />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
