import React from "react";
import { Modal } from "reactstrap";

function CustomModal({ children, toggle, setToggle, size = "md" }) {
  function toggleModal() {
    setToggle(!toggle);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size={size}
        isOpen={toggle}
        toggle={toggleModal}
      >
        {children}
      </Modal>
    </>
  );
}

export default CustomModal;
