import { get } from "axios/apiCall";
import { COMMUNITY_RESOURCE } from "axios/apiUrl";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import ResourceTableComponent from "./table/ResourceTableComponent";
import TablePagination from "components/common/table/TablePagination";
import { COMMUNITY_RESOURCE_SEARCH } from "axios/apiUrl";

export default function ResourceTable({ setSaveModalToggle, page, setPage }) {
  const [resourceList, setResourceList] = useState();
  const [resourcePaginationData, setResourcePaginationData] = useState();
  const [resourceStatus, setResourceStatus] = useState("Active");

  const [searchValue, setSearchValue] = useState("");

  const [url, setUrl] = useState(
    `${COMMUNITY_RESOURCE}?resourceStatus=${resourceStatus}&page=${page}`
  );

  useEffect(() => {
    get(`${url}`, shareResourceSuccess);
  }, [url]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setUrl(
        `${COMMUNITY_RESOURCE_SEARCH}?searchValue=${searchValue}&page=${page}`
      );
    } else {
      setUrl(
        `${COMMUNITY_RESOURCE}?resourceStatus=${resourceStatus}&page=${page}`
      );
    }
  }, [page, resourceStatus, searchValue]);

  function shareResourceSuccess(response) {
    setResourceList(response.data.data);
    setResourcePaginationData({
      totalData: response.data.totalElements,
      totalPages: response.data.totalPages,
      numberOfData: response.data.numberOfElements,
      last: response.data.last,
      first: response.data.first,
      empty: response.data.empty,
      pageNumber: response.data.pageNumber,
      perPage: response.data.perPage,
    });
  }

  function searchResource(event) {
    const value = event.target.value;
    setPage(0);
    setSearchValue(value);
    if (value.length < 3) {
      setUrl(
        `${COMMUNITY_RESOURCE}?resourceStatus=${resourceStatus}&page=${page}`
      );
    }
  }

  return (
    <>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0 pt-5">
            <div className="d-flex justify-content-between">
              <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                Reshare List
              </h3>
              <Button
                color="primary"
                className="addBtnColor"
                outline
                type="button"
                onClick={() => setSaveModalToggle(true)}
              >
                Add Reshare
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              {/* <Col sm="12" md="6" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <select
                      className="form-control border w-100 p-2"
                      value={resourceStatus}
                      onChange={(e) => {
                        setPage(0);
                        setSearchValue("");
                        setResourceStatus(e.target.value);
                      }}
                    >
                      <option value={"All"}>All Reshare</option>
                      <option value={"Active"}>Active Reshare</option>
                      <option value={"Deleted"}>Deleted Reshare</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col> */}
              <Col sm="12" md="12" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative border"
                      placeholder="Search Reshare..."
                      type="text"
                      name="title"
                      value={searchValue}
                      onChange={searchResource}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <ResourceTableComponent
              resourceList={resourceList}
              resourceCount={
                resourcePaginationData?.pageNumber *
                resourcePaginationData?.perPage
              }
              setPage={setPage}
            />
          </CardBody>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <TablePagination
                paginationData={resourcePaginationData}
                page={page}
                setPage={setPage}
              />
            </nav>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
