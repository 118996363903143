import { get } from "axios/apiCall";
import { COMMUNITY_REPORTED_POST_DETAILS } from "axios/apiUrl";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import ShowPostImageComponent from "../common/image/ShowPostImageComponent";

function ViewReportPostDetails({postData, setToggle}) {
  const [postReportList, setPostReportList] = useState([]);

  useEffect(() => {
    get(`${COMMUNITY_REPORTED_POST_DETAILS}/${postData.postId}`, postSuccess);
  }, [postData.postId]);

  function postSuccess(response) {
    if (response.success) {
      setPostReportList(response.data);
    }
  }

  return (
    <>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h3
            className="modal-title align-self-center"
            id="modal-title-default"
          >
            Reported Post Details
          </h3>
          <button
            aria-label="Close"
            className="close m-0"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </div>
      <div className="modal-body pt-0">
        {postData && postData.images && (
          <ShowPostImageComponent fileList={postData.files} />
        )}
        <hr/>
        {postData && postData.type === "Shared" && (
          <>
            <Row>
              <Col lg="3" md="4" sm="6">
                <h3>Shared By</h3>
                <p>{postData.sharedUserName}</p>
              </Col>
              <Col sm="12">
                <h3>Share Content</h3>
                <p style={{wordWrap: "break-word"}}>
                  {postData.sharedUserName}
                </p>
              </Col>
            </Row>
            <hr/>
          </>
        )}
        <Row>
          <Col lg="3" md="4" sm="6">
            <h3>Post Created By</h3>
            <p>{postData.name}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Status</h3>
            <p>{postData.postStatus}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Created Date</h3>
            <p>{moment(postData.createdAt).format("MMM DD, YYYY")}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Total Comments</h3>
            <p>{postData.commentsCount}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Total Likes</h3>
            <p>{postData.likeCount}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Total Share</h3>
            <p>{postData.shareCount}</p>
          </Col>
          <Col sm="12">
            <h3>Post Content</h3>
            <p style={{wordWrap: "break-word"}}>
              {postData.description && postData.description.length > 0 ? postData.description : 'No Content'}
            </p>
          </Col>
        </Row>
        <div className="px-1">
          {postReportList.length > 0 && (
            <>
              <h3>Report Information</h3>
              {postReportList?.map((item, index) => (
                <Row key={index} className="my-2">
                  <Col md="6" className="py-2">
                    <div className="d-flex">
                      <div className="d-flex py-2">
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={item.user.photoUrl}
                          width="30"
                          height="30"
                        />
                      </div>
                      <div className="px-2">
                        <h3 className="m-0">Report Created By</h3>
                        <span>{item.user.name}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="py-2">
                    <div className="d-flex">
                      <div className="px-2">
                        <h3 className="m-0">Report Type</h3>
                        <span>{item.reportType.name.toUpperCase()}</span>
                      </div>
                    </div>
                  </Col>
                  <Col className="py-2">
                    <div className="d-flex">
                      <div className="px-2">
                        <h3 className="m-0">Reason</h3>
                        <span>{item.reason}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewReportPostDetails;
