import { deleted } from "axios/apiCall";
import { COMMUNITY_POST_DELETE } from "axios/apiUrl";
import { customSwalAlert } from "components/alert/CustomAlert";
import { confirmDeleteAlert } from "components/alert/CustomAlert";
import CustomModal from "components/modal/CustomModal";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SaveUpdatePost from "./SaveUpdatePost";
import ViewPostDetails from "./ViewPostDetails";

export default function PostTableRowComponent({ postData, index, setPage }) {
  const [toggleViewModal, setToggleViewModal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const { userInfo } = useSelector((state) => state.userReducer);

  function deletePost() {
    deleted(
      `${COMMUNITY_POST_DELETE}/${postData.postId}`,
      postDeleteSuccess,
      postDeleteError
    );
  }

  function postDeleteSuccess(response) {
    if (response.status === 200) {
      customSwalAlert("Post Deleted!!", "success");
      // setPostStatus("Deleted");
      setPage(1);
      setPage(0);
    } else {
      customSwalAlert(response.message, "error");
    }
  }

  function postDeleteError(error) {
    customSwalAlert(error.message, "error");
  }

  function viewPost(e) {
    if (e.target.cellIndex < 5) {
      setToggleViewModal(true);
    }
  }

  const editPost = () => {
    setToggleEditModal(true);
  };

  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        onClick={viewPost}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <td>{postData.name}</td>
        <td>{postData.lifeStyle}</td>
        <td>{moment(postData.createdAt).format("MMM DD, YYYY")}</td>
        <td>{postData.postStatus.toUpperCase()}</td>
        <td style={{ cursor: "default" }}>
          <div className="d-flex justify-content-end">
            {userInfo?.user_login === postData.userName && (
              <i
                className="fas fa-edit px-2"
                style={{
                  fontSize: "1.2rem",
                  color: "#5856568c",
                  cursor: "pointer",
                }}
                onClick={() => editPost()}
              ></i>
            )}
            {postData.postStatus !== "Deleted" && (
              <i
                className="fas fa-trash px-2"
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: "#f5365c82",
                }}
                onClick={() => confirmDeleteAlert(deletePost)}
              ></i>
            )}
          </div>
        </td>
      </tr>
      {toggleEditModal && (
        <CustomModal
          toggle={toggleEditModal}
          setToggle={setToggleEditModal}
          size={"lg"}
        >
          <SaveUpdatePost
            postData={postData}
            setToggle={setToggleEditModal}
            setPage={setPage}
          />
        </CustomModal>
      )}
      {toggleViewModal && (
        <CustomModal
          toggle={toggleViewModal}
          setToggle={setToggleViewModal}
          size={"lg"}
        >
          <ViewPostDetails
            postData={postData}
            setToggle={setToggleViewModal}
            setPage={setPage}
          />
        </CustomModal>
      )}
    </>
  );
}
