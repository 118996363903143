import moment from "moment";
import React, { useState } from "react";
import { deleted } from "axios/apiCall";
import { COMMUNITY_PODCAST } from "axios/apiUrl";
import CustomModal from "../../modal/CustomModal";
import SaveUpdatePodcast from "../SaveUpdatePodcast";
import ViewPodcast from "../ViewPodcast";
import { customSwalAlert } from "components/alert/CustomAlert";
import { confirmDeleteAlert } from "components/alert/CustomAlert";

function PodcastTableRowComponent({ podcast, index, setPage, podcastStatus }) {
  console.log("=>(PodcastTableRowComponent.jsx:12) podcast", podcast);
  const [toggleUpdateModal, setToggleUpdateModal] = useState(false);
  const [toggleViewModal, setToggleViewModal] = useState(false);

  function editPodcast() {
    setToggleUpdateModal(true);
  }

  function deletePodcast() {
    deleted(
      `${COMMUNITY_PODCAST}/${podcast.slug}`,
      podcastDeleteSuccess,
      podcastDeleteError
    );
  }

  function podcastDeleteSuccess(response) {
    if (response.success) {
      customSwalAlert("Podcast Deleted!!", "success");
    } else {
      customSwalAlert(response.message, "error");
    }
  }

  function podcastDeleteError(error) {
    customSwalAlert("Server error. Please try again later!!", "error");
  }

  function viewPodcast(e) {
    if (e.target.cellIndex < 6) {
      setToggleViewModal(true);
    }
  }

  return (
    <>
      <tr
        onClick={viewPodcast}
        style={{ cursor: "pointer" }}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <th scope="row">
          <img
            alt="..."
            className="rounded-circle"
            src={podcast.imageUrl}
            width="50"
            height="50"
          />
        </th>
        <td>{podcast.author}</td>
        <td>{podcast.title}</td>
        <td>{moment(podcast.createAt).format("MMM DD, YYYY")}</td>
        <td style={{ cursor: "default" }}>
          <div className="d-flex justify-content-end">
            <i
              className="fas fa-edit px-2"
              style={{
                fontSize: "1.2rem",
                color: "#5856568c",
                cursor: "pointer",
              }}
              onClick={() => editPodcast(podcast.slug)}
            ></i>
            {podcastStatus !== "deleted" ? (
              <i
                className="fas fa-trash px-2"
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: "#f5365c82",
                }}
                onClick={() => confirmDeleteAlert(deletePodcast)}
              ></i>
            ) : (
              ""
            )}
          </div>
        </td>
      </tr>
      {toggleUpdateModal && (
        <CustomModal
          toggle={toggleUpdateModal}
          setToggle={setToggleUpdateModal}
        >
          <SaveUpdatePodcast
            setToggle={setToggleUpdateModal}
            setPage={setPage}
            podcast={podcast}
          />
        </CustomModal>
      )}
      {toggleViewModal && (
        <CustomModal
          toggle={toggleViewModal}
          setToggle={setToggleViewModal}
          size={"lg"}
        >
          <ViewPodcast
            podcast={podcast}
            setToggle={setToggleViewModal}
            setPage={setPage}
            podcastStatus={podcastStatus}
          />
        </CustomModal>
      )}
    </>
  );
}

export default PodcastTableRowComponent;
