import React from "react";
import { PaginationItem, PaginationLink } from "reactstrap";

function TablePaginationSingleItem({ count, currentPage, setPage }) {
  return (
    <>
      <PaginationItem>
        <PaginationLink
          className={`${
            currentPage === count ? "paginationActive" : ""
          } paginationBtnStyle`}
          onClick={() => setPage(count)}
        >
          {count ? count + 1 : 1}
        </PaginationLink>
      </PaginationItem>
    </>
  );
}

export default TablePaginationSingleItem;
