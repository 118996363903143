import { get } from "axios/apiCall";
import { COMMUNITY_POST } from "axios/apiUrl";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import PostTableComponent from "./PostTableComponent";
import { COMMUNITY_POST_SEARCH } from "axios/apiUrl";
import TablePagination from "components/common/table/TablePagination";

export default function PostTable({ setSaveModalToggle, page, setPage }) {
  const [postList, setPostList] = useState();
  const [postPaginationData, setPostPaginationData] = useState();
  const [postStatus, setPostStatus] = useState("Active");

  const [searchValue, setSearchValue] = useState("");

  const [url, setUrl] = useState(
    `${COMMUNITY_POST}?postStatus=${postStatus}&page=${page}`
  );

  useEffect(() => {
    get(`${url}`, postSuccess);
  }, [url]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setUrl(
        `${COMMUNITY_POST_SEARCH}?searchValue=${searchValue}&pageNo=${page}`
      );
    } else {
      setUrl(`${COMMUNITY_POST}?postStatus=${postStatus}&pageNo=${page}`);
    }
  }, [page, postStatus, searchValue]);

  function postSuccess(response) {
    // console.log("-> response", response);
    setPostList(response.data.data);
    setPostPaginationData({
      totalData: response.data.totalElements,
      totalPages: response.data.totalPages,
      numberOfData: response.data.numberOfElements,
      last: response.data.last,
      first: response.data.first,
      empty: response.data.empty,
      pageNumber: response.data.pageNumber,
      perPage: response.data.perPage,
    });
  }

  function searchPost(event) {
    const value = event.target.value;
    setPage(0);
    setSearchValue(value);
    if (value.length < 3) {
      setUrl(`${COMMUNITY_POST}?postStatus=${postStatus}&page=${page}`);
    }
  }

  return (
    <>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0 pt-5">
            <div className="d-flex justify-content-between">
              <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                Post List
              </h3>

              <Button
                color="primary"
                className="addBtnColor"
                outline
                type="button"
                onClick={() => setSaveModalToggle(true)}
              >
                Add Post
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              {/* <Col sm="12" md="6" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <select
                      className="form-control border w-100 p-2"
                      value={postStatus}
                      onChange={(e) => {
                        setPage(0);
                        setSearchValue("");
                        setPostStatus(e.target.value);
                      }}
                    >
                      // {/* <option value={""} disabled>
                      //   Select Status
                      // </option> 
                      <option value={"All"}>All Post</option>
                      <option value={"Active"}>Active Post</option>
                      <option value={"DELETED"}>Deleted Post</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col> */}
              <Col sm="12" md="12" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative border"
                      placeholder="Search..."
                      type="text"
                      name="title"
                      value={searchValue}
                      onChange={searchPost}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <PostTableComponent
              postList={postList}
              postCount={
                postPaginationData?.pageNumber * postPaginationData?.perPage
              }
              setPage={setPage}
            />
          </CardBody>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <TablePagination
                paginationData={postPaginationData}
                page={page}
                setPage={setPage}
              />
            </nav>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
