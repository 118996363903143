import { patch } from "axios/apiCall";
import { COMMUNITY_PODCAST } from "axios/apiUrl";
import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";

function ViewPodcast({ podcast, setToggle, setPage, podcastStatus }) {
  const [statusDropdown, setStatusDropdown] = useState(false);

  function statusChangeFunction() {
    if (statusDropdown === false) {
      setStatusDropdown(true);
    } else {
      const status = document.getElementById("podcastStatus").value;
      patch(
        `${COMMUNITY_PODCAST}/${podcast.slug}?podcastStatus=${status}`,
        statusChangeSuccess
      );
    }
  }

  function statusChangeSuccess(response) {
    if (response.success) {
      setPage(1);
      setPage(0);
      setToggle(false);
      toast.success("Status Changed", {
        theme: "colored",
      });
    } else {
      toast.error(response.message, {
        theme: "colored",
      });
    }
  }

  return (
    <>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h3
            className="modal-title align-self-center"
            id="modal-title-default"
          >
            Podcast Details
          </h3>
          <button
            aria-label="Close"
            className="close m-0"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </div>
      <div className="modal-body pt-0">
        <div className="d-flex justify-content-center">
          <img
            src={podcast.imageUrl}
            alt="..."
            style={{ maxWidth: "8rem", height: "8rem" }}
            className="img-thumbnail rounded-circle"
          />
        </div>
        <hr />
        <Row>
          <Col lg="2" md="3" sm="6">
            <h3>Title</h3>
            <p>{podcast.title}</p>
          </Col>
          <Col lg="2" md="3" sm="6">
            <h3>Status</h3>
            <p>{podcast.status}</p>
          </Col>
          <Col lg="4" md="6" sm="6">
            <h3>Created Date</h3>
            <p>{moment(podcast.createAt).format("MMM DD, YYYY")}</p>
          </Col>
          <Col lg="4" md="6" sm="6">
            <h3>Updated Date</h3>
            <p>{moment(podcast.updateAt).format("MMM DD, YYYY")}</p>
          </Col>
          <Col sm="12">
            <h3>Description</h3>
            <p>{podcast.description}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Podcast File</h3>
            <audio controls>
              <source
                src={podcast.fileUrl}
                className="w-100"
                type="audio/ogg"
              />
              Your browser does not support the audio element.
            </audio>
          </Col>
        </Row>
      </div>
      {podcastStatus !== "deleted" ? (
        <div className="modal-footer">
          <Row className="w-100 d-flex justify-content-end">
            {statusDropdown ? (
              <Col md="8">
                <Form>
                  <FormGroup>
                    <div className="form-group">
                      <label htmlFor="podcastStatus">State</label>
                      <select
                        id="podcastStatus"
                        className="form-control"
                        selected={podcastStatus.toUpperCase()}
                      >
                        <option value={""} disabled>
                          Select Status
                        </option>
                        <option value={"PUBLISHED"}>Published</option>
                        <option value={"UNPUBLISHED"}>Unpublished</option>
                      </select>
                    </div>
                  </FormGroup>
                </Form>
              </Col>
            ) : (
              ""
            )}
            <Col
              sm="auto"
              className="d-flex justify-content-end align-self-center pt-2 px-md-0"
            >
              <Button
                color="primary"
                className=""
                type="button"
                onClick={statusChangeFunction}
              >
                {statusDropdown ? "Save Status" : "Change Status"}
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewPodcast;
