import { Col, Container, Row } from "reactstrap";

import Header from "components/Headers/Header";
import { useEffect, useState } from "react";
import { get } from "../axios/apiCall";
import { COMMUNITY_DASHBOARD_COUNT } from "../axios/apiUrl";
import DashboardComponent from "../components/dashboard/DashboardComponent";

const Dashboard = (props) => {
  const [countDetails, setCountDetails] = useState([]);

  useEffect(() => {
    get(COMMUNITY_DASHBOARD_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    let list = [
      {
        title: "Active User",
        count: response.data.totalActiveUser,
      },
      {
        title: "Active Post",
        count: response.data.totalActivePost,
      },
      {
        title: "Active Reshare",
        count: response.data.totalActiveResource,
      },
      {
        title: "Active Podcast",
        count: response.data.totalActivePodcast,
      },
    ];

    setCountDetails(list);
  }
  return (
    <>
      <Header data={countDetails} />
      <Container className="mt--7" fluid>
        <Row className="Success my-2">
          <Col style={{ backgroundColor: "white", borderRadius: 8 }}>
            <DashboardComponent />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
