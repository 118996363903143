import React from "react";
import { Table } from "reactstrap";
import UserTableRowComponent from "./UserTableRowComponent";

export default function UserTableComponent({ userList, userCount, setPage }) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="tableHeaderStyle">
            <th scope="col">#</th>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col">
              <span className="d-flex justify-content-end">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {userList && userList?.length > 0 ? (
            userList?.map((user, index) => {
              return (
                <UserTableRowComponent
                  user={user}
                  index={index + userCount + 1}
                  key={index}
                  setPage={setPage}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No Users
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
