import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import authMenuRoutes from "routes/authMenuRoutes";
import paths from "variables/paths";

const AuthNavbar = () => {
  return (
    <>
      <Navbar
        className="navbar-top navbar-horizontal navbar-dark"
        style={{ backgroundColor: "#016b4f" }}
        expand="md"
      >
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={require("../../assets/img/ecomilli-logo.png").default}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to={paths.adminDashboard}>
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/ecomilli-logo.png").default
                      }
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              {authMenuRoutes.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className="nav-link-icon"
                      to={item.path}
                      tag={Link}
                    >
                      <i className={item.icon} />
                      <span className="nav-link-inner--text">{item.name}</span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AuthNavbar;
