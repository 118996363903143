import axios from "axios";
import { COMMUNITY_ADMIN_LOGIN, COMMUNITY_ADMIN_LOGOUT } from "axios/apiUrl";
import { toast } from "react-toastify";
import { getCookies, removeCookies, setCookies } from "./authCookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_PREFIX,
} from "./authCookieType";

export default async function loginHelper(credentials, history, setLoading) {
  try {
    const { data } = await axios.post(COMMUNITY_ADMIN_LOGIN, credentials);
    setLoading(false);
    if (data.status === 200) {
      setCookies(ACCESS_TOKEN_COOKIE_NAME, data.access_token.token, {
        expires: data.access_token.expires,
      });
      setCookies(REFRESH_TOKEN_COOKIE_NAME, data.refresh_token.token, {
        expires: data.refresh_token.expires,
      });
      history.push("/");
      toast.success("Login Successful", {
        theme: "colored",
      });
    } else {
      toast.error(data.message, {
        theme: "colored",
      });
    }
  } catch (error) {
    setLoading(false);
    toast.error("Login error. Please try again!", {
      theme: "colored",
    });
  }
}

export function isLoggedIn() {
  const token = getCookies(ACCESS_TOKEN_COOKIE_NAME);
  return token !== undefined && token !== "";
}

export function logoutHelper(history) {
  let client = axios.create();
  client.defaults.headers.common["ecommunityrefreshtoken"] =
    REFRESH_TOKEN_PREFIX + getCookies(REFRESH_TOKEN_COOKIE_NAME);

  client
    .get(COMMUNITY_ADMIN_LOGOUT)
    .then((res) => {
      if (res.status === 200) {
        if (history) {
          history?.replace("/auth/login");
        }else {
          window.location.href = "/auth/login";
        }
        removeCookies(ACCESS_TOKEN_COOKIE_NAME);
        removeCookies(REFRESH_TOKEN_COOKIE_NAME);
      } else if (res.data.status === 401) {
        if (history) {
          history?.replace("/auth/login");
        }else {
          window.location.href = "/auth/login";
        }
        removeCookies(ACCESS_TOKEN_COOKIE_NAME);
        removeCookies(REFRESH_TOKEN_COOKIE_NAME);
      } else {
        toast.error(res.data.message, {
          theme: "colored",
        });
      }
    });
}
