import moment from "moment";
import React, { useState } from "react";
import CustomModal from "../../modal/CustomModal";
import ViewPodcastRequestDetails from "../ViewPodcastRequestDetails";
import { get } from "../../../axios/apiCall";
import { COMMUNITY_PODCAST_REQUEST_DETAILS } from "../../../axios/apiUrl";
import {AcceptPodcastRequestModal} from "../AcceptPodcastRequestModal";

function PodcastRequestTableRowComponent({ podcastRequest, index, setPage }) {
  const [toggleViewModal, setToggleViewModal] = useState(false);
  const [acceptRequestModalOpen, setAcceptRequestModalOpen] = useState(false);

  function viewPodcast(e) {
    setToggleViewModal(true);
  }
  return (
    <>
      <tr
        onClick={viewPodcast}
        style={{ cursor: "pointer" }}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <td>{podcastRequest.podcastTitle}</td>
        <td>{podcastRequest.uploadedBy}</td>
        <td>{podcastRequest.email}</td>
        <td>{podcastRequest.status}</td>
        <td>{moment(podcastRequest.createAt).format("MMM DD, YYYY")}</td>
      </tr>
      {toggleViewModal && podcastRequest && (
        <CustomModal
          toggle={toggleViewModal}
          setToggle={setToggleViewModal}
          size={"lg"}
        >
          <ViewPodcastRequestDetails
            podcastRequestDetails={podcastRequest}
            setToggle={setToggleViewModal}
            setAcceptRequestModalOpen={setAcceptRequestModalOpen}
            setPage={setPage}
          />
        </CustomModal>
      )}
      {acceptRequestModalOpen && (
          <CustomModal
              toggle={true}
              setToggle={setAcceptRequestModalOpen}
              size={"md"}
          >
            <AcceptPodcastRequestModal
                setPage={setPage}
                podcastRequest={podcastRequest}
                setToggle={setAcceptRequestModalOpen}
            />
          </CustomModal>
      )}
    </>
  );
}

export default PodcastRequestTableRowComponent;
