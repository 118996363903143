import React from "react";
import { ScaleLoader } from "react-spinners";
import { Button } from "reactstrap";

export default function LoaderButtonComponent({
  text,
  onClick = () => {},
  loading = false,
  type = "button",
    color="primary"
}) {
  return (
    <>
      <Button
        className="my-4"
        color={color}
        type={type}
        disabled={loading}
        onClick={onClick}
      >
        <div className="d-flex justify-content-center align-items-center">
          {loading ? (
            <span className="px-2">
              <ScaleLoader height={20} width={2} color={"white"} />
            </span>
          ) : (
            text
          )}
        </div>
      </Button>
    </>
  );
}
