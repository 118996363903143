import React, {useState} from 'react'
import {Button, Card, CardBody, CardHeader, Form, FormGroup, Input, InputGroup} from "reactstrap";
import LoaderButtonComponent from "../common/LoaderButtonComponent";
import {COMMUNITY_BASE_URL, COMMUNITY_UPDATE_PODCAST_REQUEST_STATUS} from "../../axios/apiUrl";
import {post, put} from "../../axios/apiCall";
import {toast} from "react-toastify";

export const AcceptPodcastRequestModal = ({setPage, podcastRequest, setToggle}) => {
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const [changeImage, setChangeImage] = useState(!podcastRequest.imageLink);
    const [changeAudio, setChangeAudio] = useState(!podcastRequest.audioFileLink);

    const [selectedFileData, setSelectedFileData] = useState({});

    function selectedFile(event) {
        if (event.target.value !== "") {
            setSelectedFileData(prevState => ({
                ...prevState,
                [event.target.id]: event.target.files[0]
            }))
            document.getElementById(`${event.target.id}Label`).innerHTML =
                document.getElementById(event.target.id).files[0].name;
        }
    }

    const acceptRequest = (event) => {
        event.preventDefault();
        setAcceptLoading(true);
        const formData = new FormData();
        formData.append("imageFile", selectedFileData.imageUrl)
        formData.append("audioFile", selectedFileData.fileUrl)
        put(`${COMMUNITY_UPDATE_PODCAST_REQUEST_STATUS}/${podcastRequest.id}?status=accepted`, formData,
            (response) => acceptRequestSuccess(response, "accept"), acceptRequestError);
    }

    const rejectRequest = () => {
        setRejectLoading(true);
        put(`${COMMUNITY_UPDATE_PODCAST_REQUEST_STATUS}/${podcastRequest.id}?status=rejected`, {},
            (response) => acceptRequestSuccess(response, "reject"), acceptRequestError);
    }

    const acceptRequestSuccess = (response, type) => {
        setRejectLoading(false);
        setAcceptLoading(false);
        if (response.success) {
            setPage(1);
            setPage(0);
            setToggle(false);
            if (type === 'accept') {
                toast.success("Request Accepted", {theme: 'colored'});
            } else {
                toast.success("Request Rejected", {theme: 'colored'});
            }
        } else {
            toast.error(response.message, {theme: 'colored'});
        }
    }

    const acceptRequestError = (error) => {
        setRejectLoading(false);
        setAcceptLoading(false);
        toast.error(error.message);
    }

    return (
        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent">
                    <div className="text-muted text-center mt-2 mb-3">
                        <h2>Accept Request</h2>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form" onSubmit={acceptRequest}>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    className="form-control-alternative"
                                    placeholder="Title"
                                    type="text"
                                    name="title"
                                    disabled
                                    defaultValue={podcastRequest.podcastTitle}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    className="form-control-alternative"
                                    placeholder="Podcast Author"
                                    type="text"
                                    name="author"
                                    disabled
                                    defaultValue={podcastRequest.podcastAuthor}
                                />
                            </InputGroup>
                        </FormGroup>
                        {!changeImage ? (
                            <div className="d-flex justify-content-between align-items-center">
                                <img
                                    src={`${COMMUNITY_BASE_URL}/images/podcast/images/${podcastRequest.imageLink}`}
                                    alt="..."
                                    style={{maxWidth: "4rem", height: "4rem"}}
                                    className="img-thumbnail rounded-circle"
                                />
                                <Button
                                    className="my-4"
                                    color="info"
                                    type="button"
                                    onClick={() => setChangeImage(true)}
                                >
                                    Change Image
                                </Button>
                            </div>
                        ) : (
                            <FormGroup>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="imageUrl"
                                        lang="es"
                                        name="imageUrl"
                                        onChange={selectedFile}
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="imageUrl"
                                        id="imageUrlLabel"
                                    >
                                        Select Image
                                    </label>
                                </div>
                            </FormGroup>
                            )}

                        {!changeAudio ? (
                            <div className="d-flex justify-content-between align-items-center">
                                <audio controls style={{width: '62%'}}>
                                    <source
                                        src={`${COMMUNITY_BASE_URL}/images/podcast/files/${podcastRequest.audioFileLink}`}
                                        type="audio/ogg"
                                    />
                                    Your browser does not support the audio element.
                                </audio>
                                <Button
                                    className="my-4"
                                    color="info"
                                    type="button"
                                    onClick={() => setChangeAudio(true)}
                                >
                                    Change Audio
                                </Button>
                            </div>
                        ) : (
                            <FormGroup>
                                <div className="custom-file">
                                    <label
                                        className="custom-file-label"
                                        htmlFor="fileUrl"
                                        id="fileUrlLabel"
                                    >
                                        Select File
                                    </label>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="fileUrl"
                                        lang="es"
                                        name="fileUrl"
                                        onChange={selectedFile}
                                    />
                                </div>
                            </FormGroup>
                        )}

                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    className="form-control-alternative"
                                    placeholder="Description"
                                    name="description"
                                    disabled
                                    defaultValue={podcastRequest.podcastDescription}
                                    rows="3"
                                    type="textarea"
                                />
                            </InputGroup>
                        </FormGroup>

                        <div className="text-center d-flex">
                            <Button
                                className="my-4"
                                color="info"
                                type="button"
                                disabled={acceptLoading || rejectLoading}
                                onClick={() => setToggle(false)}
                            >
                                Cancel
                            </Button>
                            <LoaderButtonComponent
                                text={"Accept Request"}
                                loading={acceptLoading}
                                type={"submit"}
                            />
                            <LoaderButtonComponent
                                text={"Reject Request"}
                                loading={rejectLoading}
                                color={"danger"}
                                onClick={rejectRequest}
                            />
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}
