import { get } from "axios/apiCall";
import { COMMUNITY_RESOURCE_COUNT } from "axios/apiUrl";
import Header from "components/Headers/Header";
import CustomModal from "components/modal/CustomModal";
import ResourceTable from "components/resource/ResourceTable";
import SaveUpdateResource from "components/resource/SaveUpdateResource";
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

function Resource() {
  const [saveModalToggle, setSaveModalToggle] = useState(false);
  const [page, setPage] = useState(0);
  const [resourceCountDetails, setResourceCountDetails] = useState([]);

  useEffect(() => {
    get(COMMUNITY_RESOURCE_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    let list = [
      {
        title: "Todays Reshare",
        count: response.todaysData,
      },
      {
        title: "This Months Reshare",
        count: response.thisMonthData,
      },
      {
        title: "Active Reshare",
        count: response.activeData,
      },
      {
        title: "Total Reshare",
        count: response.totalData,
      },
    ];

    setResourceCountDetails(list);
  }

  return (
    <>
      <Header data={resourceCountDetails} />
      <Container className="mt--7" fluid>
        <Row>
          <ResourceTable
            setSaveModalToggle={setSaveModalToggle}
            page={page}
            setPage={setPage}
          />
        </Row>
      </Container>
      {saveModalToggle ? (
        <CustomModal toggle={saveModalToggle} setToggle={setSaveModalToggle}>
          <SaveUpdateResource
            setToggle={setSaveModalToggle}
            setPage={setPage}
          />
        </CustomModal>
      ) : (
        ""
      )}
    </>
  );
}

export default Resource;
