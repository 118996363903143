import Cookies from "universal-cookie";

const cookies = new Cookies();

export function setCookies(name, value) {
  cookies.set(name, value, { path: "/" });
}

export function getCookies(name) {
  return cookies.get(name);
}

export function removeCookies(name) {
  cookies.remove(name, { path: "/" });
}
