const paths = {
  root: "/",
  admin: "/admin",
  auth: "/auth",
  adminDashboard: "/admin/dashboard",
  adminUser: "/admin/user",
  adminPost: "/admin/post",
  adminResource: "/admin/resource",
  // adminGroup: "/admin/group",
  adminPodcast: "/admin/podcast",
  adminPodcastRequest: "/admin/podcast-request",
  adminReportType: "/admin/report_type",
  adminReportPost: "/admin/report_post",

  //AUTH ROUTES
  authLogin: "/auth/login",
  authRegister: "/auth/register",
};

export default paths;
