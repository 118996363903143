import React from "react";
import { Table } from "reactstrap";
import PodcastRequestTableRowComponent from "./PodcastRequestTableRowComponent";

function PodcastRequestTableComponent({
                                        podcastRequestList,
                                        podcastCount,
                                        setPage,
}) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="tableHeaderStyle">
            <th scope="col">#</th>
            <th scope="col">Title</th>
            <th scope="col">Requested By</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col">Created Date</th>
          </tr>
        </thead>
        <tbody>
          {podcastRequestList && podcastRequestList?.length > 0 ? (
            podcastRequestList?.map((podcastRequest, index) => {
              return (
                <PodcastRequestTableRowComponent
                  podcastRequest={podcastRequest}
                  index={index + podcastCount + 1}
                  key={podcastRequest.id}
                  setPage={setPage}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={7} style={{ textAlign: "center" }}>
                No Podcast Request
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default PodcastRequestTableComponent;
