import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/table.css";
import "assets/css/SideNav.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import paths from "variables/paths";
import { ToastContainer } from "react-toastify";
import store from "./redux/store";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Switch>
        <Route
          path={paths.admin}
          render={(props) => <AdminLayout {...props} />}
        />
        <Route
          path={paths.auth}
          render={(props) => <AuthLayout {...props} />}
        />
        <Redirect from={paths.root} to={paths.adminDashboard} />
      </Switch>
    </Provider>
    <ToastContainer newestOnTop limit={3} />
  </BrowserRouter>,
  document.getElementById("root")
);
