import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import { Container } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";

import paths from "variables/paths";
import sideBarRoutes from "routes/sideBarRoutes";
import { isLoggedIn } from "login/loginHelper";
import { useHistory } from "react-router-dom";
import { get } from "axios/apiCall";
import { COMMUNITY_ADMIN_DATA } from "axios/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "redux/user/userAction";
import { setIsAuthenticated } from "redux/user/userAction";
import { getCookies } from "login/authCookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "login/authCookieType";

const Admin = (props) => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userReducer);

  const [token] = useState(getCookies(ACCESS_TOKEN_COOKIE_NAME));

  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    if (!isLoggedIn()) {
      history.push("/auth/login");
    } else {
      if (!userInfo && token) {
        get(COMMUNITY_ADMIN_DATA, adminDataSuccess, adminDataError);
      }
    }
  }, []);

  function adminDataSuccess(response) {
    dispatch(setUserInfo(response.data));
    dispatch(setIsAuthenticated(response.status === 200));
  }

  function adminDataError(error) {
    console.log(error);
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === paths.admin) {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < sideBarRoutes.length; i++) {
      if (path === sideBarRoutes[i].path) {
        return sideBarRoutes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={sideBarRoutes}
        logo={{
          innerLink: paths.adminDashboard,
          imgSrc: require("../assets/img/ecomilli-logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <div
          className="py-md-2 d-none d-md-block"
          style={{ backgroundColor: "#016b4f" }}
        >
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
        </div>
        <Switch minHeight="500px">
          {getRoutes(sideBarRoutes)}
          <Redirect from="/" to={paths.adminDashboard} />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
