import { deleted } from "axios/apiCall";
import { COMMUNITY_REPORT_TYPE } from "axios/apiUrl";
import { confirmDeleteAlert } from "components/alert/CustomAlert";
import { customSwalAlert } from "components/alert/CustomAlert";
import CustomModal from "components/modal/CustomModal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsReportTypeDataChanged } from "redux/report/ReportAction";
import SaveUpdateReportType from "../SaveUpdateReportType";

function ReportTypeTableRowComponent({ reportType, index }) {
  const dispatch = useDispatch();

  const [toggleUpdateModal, setToggleUpdateModal] = useState(false);

  function editReportType() {
    setToggleUpdateModal(true);
  }

  function deleteReportType() {
    deleted(
      `${COMMUNITY_REPORT_TYPE}/${reportType.id}`,
      reportTypeDeleteSuccess,
      reportTypeDeleteError
    );
  }

  function reportTypeDeleteSuccess(response) {
    if (response.success) {
      dispatch(setIsReportTypeDataChanged());
      customSwalAlert("Report Type Deleted!!", "success");
    } else {
      customSwalAlert(response.message, "error");
    }
  }

  function reportTypeDeleteError(error) {
    customSwalAlert("Server error. Please try again later!!", "error");
  }

  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <td>{reportType.name}</td>
        <td>
          <span className="d-flex justify-content-center">
            {reportType.status}
          </span>
        </td>
        <td style={{ cursor: "default" }}>
          <div className="d-flex justify-content-end">
            {reportType?.status?.toLowerCase() !== "deleted" && (
              <i
                className="fas fa-trash px-2"
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: "#f5365c82",
                }}
                onClick={() => confirmDeleteAlert(deleteReportType)}
              ></i>
            )}
            <i
              className="fas fa-edit px-2"
              style={{
                fontSize: "1.2rem",
                color: "#5856568c",
                cursor: "pointer",
              }}
              onClick={() => editReportType(reportType.id)}
            ></i>
          </div>
        </td>
      </tr>
      {toggleUpdateModal && (
        <CustomModal
          toggle={toggleUpdateModal}
          setToggle={setToggleUpdateModal}
        >
          <SaveUpdateReportType
            setToggle={setToggleUpdateModal}
            reportType={reportType}
          />
        </CustomModal>
      )}
    </>
  );
}

export default ReportTypeTableRowComponent;
