import { deleted } from "axios/apiCall";
import { COMMUNITY_POST_DELETE } from "axios/apiUrl";
import { customSwalAlert } from "components/alert/CustomAlert";
import { confirmDeleteAlert } from "components/alert/CustomAlert";
import CustomModal from "components/modal/CustomModal";
import moment from "moment";
import React, { useState } from "react";
import ViewReportPostDetails from "../ViewReportPostDetails";

export default function ReportPostTableRowComponent({ postData, index }) {
  const [toggleViewModal, setToggleViewModal] = useState(false);

  function deletePost() {
    deleted(
      `${COMMUNITY_POST_DELETE}/${postData.postId}`,
      postDeleteSuccess,
      postDeleteError
    );
  }

  function postDeleteSuccess(response) {
    if (response.status === 200) {
      customSwalAlert("Post Deleted!!", "success");
    } else {
      customSwalAlert(response.message, "error");
    }
  }

  function postDeleteError(error) {
    customSwalAlert(error.message, "error");
  }

  function viewPost(e) {
    if (e.target.cellIndex < 5) {
      setToggleViewModal(true);
    }
  }

  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        onClick={viewPost}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <td>{postData.name}</td>
        <td>{postData.lifeStyle}</td>
        <td>{moment(postData.createdAt).format("MMM DD, YYYY")}</td>
        <td>{postData.postStatus.toUpperCase()}</td>
        <td style={{ cursor: "default" }}>
          <div className="d-flex justify-content-end">
            {postData.postStatus !== "Deleted" && (
              <i
                className="fas fa-trash px-2"
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: "#f5365c82",
                }}
                onClick={() => confirmDeleteAlert(deletePost)}
              ></i>
            )}
          </div>
        </td>
      </tr>
      {toggleViewModal && (
        <CustomModal
          toggle={toggleViewModal}
          setToggle={setToggleViewModal}
          size={"lg"}
        >
          <ViewReportPostDetails
            postData={postData}
            setToggle={setToggleViewModal}
          />
        </CustomModal>
      )}
    </>
  );
}
