import { deleted } from "axios/apiCall";
import { COMMUNITY_RESOURCE_DELETE } from "axios/apiUrl";
import { customSwalAlert } from "components/alert/CustomAlert";
import { confirmDeleteAlert } from "components/alert/CustomAlert";
import CustomModal from "components/modal/CustomModal";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SaveUpdateResource from "../SaveUpdateResource";
import ViewResourceDetails from "../ViewResourceDetails";

export default function ResourceTableRowComponent({
  resource,
  index,
  setPage,
}) {
  const [toggleViewModal, setToggleViewModal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const { userInfo } = useSelector((state) => state.userReducer);

  function deleteSharePost() {
    deleted(
      `${COMMUNITY_RESOURCE_DELETE}/${resource.resourceId}`,
      resourceDeleteSuccess,
      resourceDeleteError
    );
  }

  function resourceDeleteSuccess(response) {
    if (response.status === 200) {
      customSwalAlert("Data Deleted!!", "success");
    } else {
      customSwalAlert(response.message, "error");
    }
  }

  function resourceDeleteError(error) {
    customSwalAlert(error.message, "error");
  }

  function viewSharePost(e) {
    if (e.target.cellIndex < 4) {
      setToggleViewModal(true);
    }
  }

  const editPost = () => {
    setToggleEditModal(true);
  };

  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        onClick={viewSharePost}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <td>{resource.name}</td>
        <td>{moment(resource.createdAt).format("MMM DD, YYYY")}</td>
        <td>{resource.zipCode}</td>
        <td>{resource.resourceStatus.toUpperCase()}</td>
        <td style={{ cursor: "default" }}>
          <div className="d-flex justify-content-end">
            {userInfo?.user_login === resource.userName && (
              <i
                className="fas fa-edit px-2"
                style={{
                  fontSize: "1.2rem",
                  color: "#5856568c",
                  cursor: "pointer",
                }}
                onClick={() => editPost()}
              ></i>
            )}
            {resource.resourceStatus.toLowerCase() ===
              "Active".toLowerCase() && (
              <i
                className="fas fa-trash px-2"
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: "#f5365c82",
                }}
                onClick={() => confirmDeleteAlert(deleteSharePost)}
              ></i>
            )}
          </div>
        </td>
      </tr>
      {toggleEditModal && (
        <CustomModal
          toggle={toggleEditModal}
          setToggle={setToggleEditModal}
          size={"lg"}
        >
          <SaveUpdateResource
            resourceData={resource}
            setToggle={setToggleEditModal}
            setPage={setPage}
          />
        </CustomModal>
      )}
      {toggleViewModal && (
        <CustomModal
          toggle={toggleViewModal}
          setToggle={setToggleViewModal}
          size={"lg"}
        >
          <ViewResourceDetails
            resourceData={resource}
            setToggle={setToggleViewModal}
            setPage={setPage}
          />
        </CustomModal>
      )}
    </>
  );
}
