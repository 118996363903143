import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";

function ViewUserDetails({ user, setToggle }) {
  return (
    <>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h3
            className="modal-title align-self-center"
            id="modal-title-default"
          >
            User Details
          </h3>
          <button
            aria-label="Close"
            className="close m-0"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </div>
      <div className="modal-body pt-0">
        <div className="d-flex justify-content-center">
          <img
            src={user.photoUrl}
            alt="..."
            style={{ maxWidth: "8rem", height: "8rem" }}
            className="img-thumbnail rounded-circle"
          />
        </div>
        <hr />
        <Row>
          <Col sm="6">
            <h3>Name</h3>
            <p>{user.name}</p>
          </Col>
          <Col sm="6">
            <h3>Email</h3>
            <p>{user.email}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Status</h3>
            <p>{user.status.toUpperCase()}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Created Date</h3>
            <p>{moment(user.createAt).format("MMM DD, YYYY")}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Updated Date</h3>
            <p>{moment(user.updateAt).format("MMM DD, YYYY")}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Role</h3>
            <p>{user.role.toUpperCase()}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Zip Code</h3>
            <p>{user.zipCode}</p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ViewUserDetails;
