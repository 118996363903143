import { get } from "axios/apiCall";
import { COMMUNITY_USER_COUNT } from "axios/apiUrl";
import Header from "components/Headers/Header";
import UserTable from "components/user/UserTable";
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

function User() {
  const [page, setPage] = useState(0);
  const [userCountDetails, setUserCountDetails] = useState([]);

  useEffect(() => {
    get(COMMUNITY_USER_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    let list = [
      {
        title: "Todays Registered User",
        count: response.todaysData,
      },
      {
        title: "This Month Registered User",
        count: response.thisMonthData,
      },
      {
        title: "Active Registered User",
        count: response.activeData,
      },
      {
        title: "Total Registered User",
        count: response.totalData,
      },
    ];

    setUserCountDetails(list);
  }

  return (
    <>
      <Header data={userCountDetails} />
      <Container className="mt--7" fluid>
        <Row>
          <UserTable page={page} setPage={setPage} />
        </Row>
      </Container>
    </>
  );
}

export default User;
