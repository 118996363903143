import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import TablePaginationSingleItem from "./TablePaginationSingleItem";

function TablePagination({ paginationData, page, setPage }) {
  const [isLessThenSeven, setIsLessThenSeven] = useState(false);

  const [firstPaginationList, setFirstPaginationList] = useState([]);
  const [secondPaginationList, setSecondPaginationList] = useState([]);

  useEffect(() => {
    if (paginationData) {
      const totalPage = paginationData.totalPages;
      if (totalPage > 7) {
        setIsLessThenSeven(false);
        setFirstPaginationList([0, 1, 2]);
        setSecondPaginationList([totalPage - 3, totalPage - 2, totalPage - 1]);
      } else {
        setIsLessThenSeven(true);
      }
    }
  }, [paginationData?.totalPages]);

  useEffect(() => {
    const middle = parseInt(paginationData?.totalPages / 2);
    if (
      !isLessThenSeven &&
      page !== 0 &&
      page !== paginationData?.totalPages - 1
    ) {
      if (page < middle - 1) {
        setFirstPaginationList([page - 1, page, page + 1]);
      } else if (page > middle + 1) {
        setSecondPaginationList([page - 1, page, page + 1]);
      } else if (page === middle - 1) {
        setFirstPaginationList([page - 2, page - 1, page]);
      } else if (page === middle + 1) {
        setSecondPaginationList([page, page + 1, page + 2]);
      }
    }
    // for(let i)
  }, [page]);

  function getPageNumberHtml() {
    if (isLessThenSeven) {
      let pageIndexList = [];
      for (let i = 0; i < paginationData?.totalPages; i++) {
        pageIndexList.push(i);
      }
      return pageIndexList;
    }
  }
  return (
    <>
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={paginationData?.first ? "disabled" : ""}>
          <PaginationLink
            onClick={() => setPage(page - 1)}
            tabIndex="-1"
            className="paginationBtnStyle"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {isLessThenSeven ? (
          getPageNumberHtml().map((count, index) => {
            return (
              <TablePaginationSingleItem
                key={index}
                count={count}
                currentPage={paginationData?.pageNumber}
                setPage={setPage}
              />
            );
          })
        ) : (
          <>
            {firstPaginationList.map((count, index) => {
              return (
                <TablePaginationSingleItem
                  key={index}
                  count={count}
                  currentPage={paginationData?.pageNumber}
                  setPage={setPage}
                />
              );
            })}
            <TablePaginationSingleItem
              count={parseInt(paginationData?.totalPages / 2)}
              currentPage={paginationData?.pageNumber}
              setPage={setPage}
            />
            {secondPaginationList.map((count, index) => {
              return (
                <TablePaginationSingleItem
                  key={index}
                  count={count}
                  currentPage={paginationData?.pageNumber}
                  setPage={setPage}
                />
              );
            })}
          </>
        )}
        <PaginationItem className={paginationData?.last ? "disabled" : ""}>
          <PaginationLink
            onClick={() => setPage(page + 1)}
            className="paginationBtnStyle"
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </>
  );
}

export default TablePagination;
