// ------------ Community -------------
export const COMMUNITY_BASE_URL = process.env.REACT_APP_COMMUNITY_BASE_URL;
const COMMUNITY_BASE_PATH = `${COMMUNITY_BASE_URL}/ecomilli-community/api/v1`;

export const COMMUNITY_ADMIN = `${COMMUNITY_BASE_PATH}/admin`;

//USER
export const COMMUNITY_USER = `${COMMUNITY_ADMIN}/user`;
export const COMMUNITY_USER_SEARCH = `${COMMUNITY_USER}/search`;
export const COMMUNITY_USER_COUNT = `${COMMUNITY_USER}/count`;
export const COMMUNITY_USER_DELETE = `${COMMUNITY_ADMIN}/userDelete`;

//POST
export const COMMUNITY_POST = `${COMMUNITY_ADMIN}/post`;
export const COMMUNITY_POST_SEARCH = `${COMMUNITY_POST}/search`;
export const COMMUNITY_POST_COUNT = `${COMMUNITY_POST}/count`;
export const COMMUNITY_POST_DELETE = `${COMMUNITY_ADMIN}/postDelete`;

//RESOURCE
export const COMMUNITY_RESOURCE = `${COMMUNITY_ADMIN}/resource`;
export const COMMUNITY_RESOURCE_COUNT = `${COMMUNITY_RESOURCE}/count`;
export const COMMUNITY_RESOURCE_SEARCH = `${COMMUNITY_RESOURCE}/search`;
export const COMMUNITY_RESOURCE_DELETE = `${COMMUNITY_ADMIN}/resourceDelete`;

//Group
export const COMMUNITY_GROUPS = `${COMMUNITY_BASE_PATH}/groups`;
export const COMMUNITY_GROUPS_ALL = `${COMMUNITY_GROUPS}/all`;

//PODCAST
export const COMMUNITY_PODCAST = `${COMMUNITY_BASE_PATH}/podcast`;
export const COMMUNITY_PODCAST_PUBLISHED = `${COMMUNITY_PODCAST}/published`;
export const COMMUNITY_PODCAST_UNPUBLISHED = `${COMMUNITY_PODCAST}/unpublished`;
export const COMMUNITY_PODCAST_DELETED = `${COMMUNITY_PODCAST}/deleted`;
export const COMMUNITY_PODCAST_SEARCH = `${COMMUNITY_PODCAST}/search`;
export const COMMUNITY_PODCAST_COUNT = `${COMMUNITY_PODCAST}/count`;


//PODCAST Request
export const COMMUNITY_PODCAST_REQUEST_COUNT = `${COMMUNITY_PODCAST}/request/count`;
export const COMMUNITY_PODCAST_REQUEST = `${COMMUNITY_PODCAST}/podcast-request`;
export const COMMUNITY_PODCAST_REQUEST_DETAILS = `${COMMUNITY_PODCAST_REQUEST}/detail`;
export const COMMUNITY_ACCEPT_PODCAST_REQUEST = `${COMMUNITY_PODCAST_REQUEST}/update-status`;
export const COMMUNITY_UPDATE_PODCAST_REQUEST_STATUS = `${COMMUNITY_PODCAST}/request/accept`;

//REPORT TYPE
export const COMMUNITY_REPORT_TYPE = `${COMMUNITY_BASE_PATH}/report_type`;
export const COMMUNITY_REPORT_TYPE_ALL = `${COMMUNITY_BASE_PATH}/report_type/getAll`;

//REPORT POST
export const COMMUNITY_REPORTED_POST_COUNT = `${COMMUNITY_ADMIN}/post/reported/count`;
export const COMMUNITY_REPORTED_POST_LIST = `${COMMUNITY_ADMIN}/reported-post`;
export const COMMUNITY_REPORTED_POST_DETAILS = `${COMMUNITY_ADMIN}/post/reports`;

//Dashboard
export const COMMUNITY_DASHBOARD = `${COMMUNITY_ADMIN}/dashboard`;
export const COMMUNITY_DASHBOARD_COUNT = `${COMMUNITY_DASHBOARD}/count`;

//LOGIN
export const MARCKETPLACE_BASE_URL =
  process.env.REACT_APP_MARCKETPLACE_BASE_URL;
export const COMMUNITY_ADMIN_LOGIN = `${MARCKETPLACE_BASE_URL}/community/admin_login`;
export const COMMUNITY_ADMIN_LOGIN_ACCESS_TOKEN = `${MARCKETPLACE_BASE_URL}/community/admin/access_token`;
export const COMMUNITY_ADMIN_LOGOUT = `${MARCKETPLACE_BASE_URL}/community/admin_logout`;

export const COMMUNITY_ADMIN_DATA = `${MARCKETPLACE_BASE_URL}/community/admin_data`;

//MARKETPLACE
export const MARKETPLACE_LIFESTYLE = `${MARCKETPLACE_BASE_URL}/lifestyles`;
