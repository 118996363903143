import React, { useEffect, useState } from "react";
import "../../assets/style/table.css";
import CustomModal from "components/modal/CustomModal";
import SaveUpdatePodcast from "components/podcast/SaveUpdatePodcast";
import PublishedPodcastTable from "components/podcast/PublishedPodcastTable";
import Header from "components/Headers/Header";
import { get } from "axios/apiCall";
import { COMMUNITY_PODCAST_COUNT } from "axios/apiUrl";

function Podcast() {
  const [saveModalToggle, setSaveModalToggle] = useState(false);
  const [page, setPage] = useState(0);

  const [podcastCountDetails, setPodcastCountDetails] = useState([]);

  function savePodcast() {
    setSaveModalToggle(true);
  }

  useEffect(() => {
    get(COMMUNITY_PODCAST_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    if (response.success) {
      let list = [
        {
          title: "Todays Podcast",
          count: response.data.todaysData,
        },
        {
          title: "This Month Podcast",
          count: response.data.thisMonthData,
        },
        {
          title: "Published Podcast",
          count: response.data.activeData,
        },
        {
          title: "Total Podcast",
          count: response.data.totalData,
        },
      ];

      setPodcastCountDetails(list);
    }
  }

  return (
    <>
      <Header data={podcastCountDetails} />
      <PublishedPodcastTable
        savePodcast={savePodcast}
        page={page}
        setPage={setPage}
      />

      {saveModalToggle ? (
        <CustomModal toggle={saveModalToggle} setToggle={setSaveModalToggle}>
          <SaveUpdatePodcast setToggle={setSaveModalToggle} setPage={setPage} />
        </CustomModal>
      ) : (
        ""
      )}
    </>
  );
}

export default Podcast;
