import { get } from "axios/apiCall";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  InputGroup,
  Row,
} from "reactstrap";
import TablePagination from "components/common/table/TablePagination";
import { COMMUNITY_REPORTED_POST_LIST } from "axios/apiUrl";
import ReportPostTableComponent from "./table/ReportPostTableComponent";

export default function ReportPostTable({ page, setPage }) {
  const [postList, setPostList] = useState();
  const [postPaginationData, setPostPaginationData] = useState();
  const [postStatus, setPostStatus] = useState("Active");

  const [url, setUrl] = useState(
    `${COMMUNITY_REPORTED_POST_LIST}?postStatus=${postStatus}&page=${page}`
  );

  useEffect(() => {
    get(`${url}`, postSuccess);
  }, [url]);

  useEffect(() => {
    setUrl(
      `${COMMUNITY_REPORTED_POST_LIST}?postStatus=${postStatus}&page=${page}`
    );
  }, [page, postStatus]);

  function postSuccess(response) {
    setPostList(response.data.data);
    setPostPaginationData({
      totalData: response.data.totalElements,
      totalPages: response.data.totalPages,
      numberOfData: response.data.numberOfElements,
      last: response.data.last,
      first: response.data.first,
      empty: response.data.empty,
      pageNumber: response.data.pageNumber,
      perPage: response.data.perPage,
    });
  }

  return (
    <>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0 pt-5">
            <div className="d-flex justify-content-between">
              <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                Post List
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            {/* <Row>
              <Col sm="12" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <select
                      className="form-control border w-100 p-2"
                      value={postStatus}
                      onChange={(e) => {
                        setPage(0);
                        setPostStatus(e.target.value);
                      }}
                    >
                      <option value={"All"}>All Post</option>
                      <option value={"Active"}>Active Post</option>
                      <option value={"DELETED"}>Deleted Post</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row> */}
            <ReportPostTableComponent
              postList={postList}
              postCount={
                postPaginationData?.pageNumber * postPaginationData?.perPage
              }
              setPage={setPage}
            />
          </CardBody>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <TablePagination
                paginationData={postPaginationData}
                page={page}
                setPage={setPage}
              />
            </nav>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
