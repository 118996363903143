import { createStore, compose } from "redux";
import { rootReducer } from "./rootReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_) ||
  compose;

const store = createStore(rootReducer, composeEnhancers());

export default store;
