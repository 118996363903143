import React from "react";
import { Table } from "reactstrap";
import PostTableRowComponent from "./PostTableRowComponent";

export default function PostTableComponent({ postList, postCount, setPage }) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="tableHeaderStyle">
            <th scope="col">#</th>
            <th scope="col">Created By</th>
            <th scope="col">Life Style</th>
            <th scope="col">Created At</th>
            <th scope="col">Status</th>
            <th scope="col">
              <span className="d-flex justify-content-end">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {postList && postList?.length > 0 ? (
            postList?.map((post, index) => {
              return (
                <PostTableRowComponent
                  postData={post}
                  index={index + postCount + 1}
                  key={index}
                  setPage={setPage}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No Posts
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
