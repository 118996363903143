import { ReportActionType } from "../Action-Type";

export const initialState = {
  isReportTypeDataChanged: false,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case ReportActionType.SET_IS_REPORT_TYPE_CHANGED:
      return {
        ...state,
        isReportTypeDataChanged: !state.isReportTypeDataChanged,
      };
    default:
      return state;
  }
}
