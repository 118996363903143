import { get } from "axios/apiCall";
import { put } from "axios/apiCall";
import { post } from "axios/apiCall";
import { COMMUNITY_POST, MARKETPLACE_LIFESTYLE } from "axios/apiUrl";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoaderButtonComponent from "../common/LoaderButtonComponent";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import ShowPostImageComponent from "../common/image/ShowPostImageComponent";

function SaveUpdatePost({ setToggle, setPage, postData }) {
  const [lifestyleList, setLifestyleList] = useState();
  const [selectedLifestyle, setSelectedLifestyle] = useState("");
  const [fileList, setFileList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    get(MARKETPLACE_LIFESTYLE, lifestyleSuccess);
  }, []);

  useEffect(() => {
    if (postData) {
      setSelectedLifestyle(postData.lifeStyle);
      setFileList(postData.files);
    }
  }, [postData]);

  function lifestyleSuccess(response) {
    if (response.status === 200) {
      setLifestyleList(response.data);
    }
  }

  function selectedFile(event, type) {
    if (event.target.value !== "") {
      document.getElementById(`${event.target.id}Label`).innerHTML = "";
      const files = event.target.files;
      for (let index = 0; index < files.length; index++) {
        document.getElementById(`${event.target.id}Label`).innerHTML +=
          files[index].name;
      }
    }
  }

  function saveUpdatePost(event) {
    event.preventDefault();
    const formData = new FormData();

    const data = event.target;
    const id = data.id.value;

    if (data.lifestyle.value === null || data.lifestyle.value === '') {
      toast.error("Please Select Lifestyle", {
        theme: "colored",
      });
      return;
    } else if (data.description.value === "" && (data.image.files.length === 0 || data.video.files.length === 0)) {
      toast.error("Please Enter Content or Files", {
        theme: "colored",
      });
      return;
    }
    const imageListLength = data.image.files.length ?? 0;
    const videoListLength = data.video.files.length ?? 0 ?? 0;
    const savedFilesLength = fileList?.length ?? 0;
    if (imageListLength + savedFilesLength + videoListLength > 8) {
      toast.error("Max 8 file can be saved!", {
        theme: "colored",
      });
      return;
    }

    // formData.append("id", id);
    // formData.append("description", data.description.value);

    for (let index = 0; index < data.image.files.length; index++) {
      formData.append("imageFiles", data.image.files[index]);
    }
    for (let index = 0; index < data.video.files.length; index++) {
      formData.append("videoFiles", data.video.files[index]);
    }
    // formData.append("lifestyle", data.lifestyle.value);

    let body = {
      description: data.description.value,
      lifestyle: data.lifestyle.value,
    }
    if (id !== "") {
      body = {
        ...body,
        id: id,
        files: fileList,
        type: postData?.type,
        privacy: postData.privacy,
        user: {id: postData.userId},
        status: postData?.postStatus,
      }
    }
    formData.append(
      "postRequestDTO",
      new Blob([JSON.stringify(body)], {type: "application/json"})
    );

    setLoading(true);
    if (id === "") {
      post(COMMUNITY_POST, formData, savePostSuccess, savePostError);
    } else {
      put(COMMUNITY_POST, formData, updatePostSuccess, savePostError);
    }
  }

  function savePostSuccess(response) {
    success(response, "Post Successfully Saved");
  }

  function updatePostSuccess(response) {
    success(response, "Post Successfully Updated");
  }

  function success(response, message) {
    setLoading(false);
    toast.success(message, {
      theme: "colored",
    });
    setPage(1);
    setPage(0);
    setToggle(false);
  }

  function savePostError(error) {
    setLoading(false);
    toast.error(error.message, {
      theme: "colored",
    });
  }

  return (
    <>
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>{postData ? "Update" : "Save"} Post</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg- py-lg-3">
            {postData && postData.files && (
              <ShowPostImageComponent
                fileList={postData.files}
                setFileList={setFileList}
              />
            )}
            <Form role="form" onSubmit={saveUpdatePost}>
              <Input
                type="text"
                name="id"
                defaultValue={postData ? postData.postId : ""}
                className="d-none"
              />
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    placeholder="Post Content..."
                    name="description"
                    defaultValue={postData ? postData.description : ""}
                    rows="3"
                    type="textarea"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Input
                  type="select"
                  name="lifestyle"
                  id="lifestyle"
                  value={selectedLifestyle}
                  onChange={(e) => setSelectedLifestyle(e.target.value)}
                >
                  <option value="" disabled>
                    Select Lifestyle
                  </option>
                  {lifestyleList &&
                    lifestyleList.map((lifestyle, index) => (
                      <option key={index} value={lifestyle.name}>
                        {lifestyle.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <div className="custom-file h-auto">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="image"
                    lang="es"
                    name="imageFiles"
                    onChange={selectedFile}
                    accept='image/png, image/jpeg'
                    multiple
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="image"
                    id="imageLabel"
                  >
                    Select Image
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="custom-file h-auto">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="video"
                    lang="es"
                    name="file"
                    multiple
                    accept='video/mp4'
                    onChange={selectedFile}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="video"
                    id="videoLabel"
                  >
                    Select Video
                  </label>
                </div>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="info"
                  type="button"
                  onClick={() => setToggle(false)}
                >
                  Cancel
                </Button>
                <LoaderButtonComponent
                  text="Save"
                  loading={loading}
                  type="submit"
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SaveUpdatePost;
