import { get } from "axios/apiCall";
import { COMMUNITY_USER_SEARCH } from "axios/apiUrl";
import { COMMUNITY_USER } from "axios/apiUrl";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";

import UserTableComponent from "./table/UserTableComponent";
import TablePagination from "../common/table/TablePagination";

export default function UserTable({ page, setPage }) {
  const [userList, setUserList] = useState();
  const [userPaginationData, setUserPaginationData] = useState();
  const [userStatus, setUserStatus] = useState("Active");

  const [searchValue, setSearchValue] = useState("");

  const [url, setUrl] = useState(
    `${COMMUNITY_USER}?userStatus=${userStatus}&page=${page}`
  );

  useEffect(() => {
    get(`${url}`, userSuccess);
  }, [page, url]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setUrl(
        `${COMMUNITY_USER_SEARCH}?searchValue=${searchValue}&page=${page}`
      );
    } else {
      setUrl(`${COMMUNITY_USER}?userStatus=${userStatus}&page=${page}`);
    }
  }, [page, userStatus, searchValue]);

  function userSuccess(response) {
    setUserList(response.data.data);
    setUserPaginationData({
      totalData: response.data.totalElements,
      totalPages: response.data.totalPages,
      numberOfData: response.data.numberOfElements,
      last: response.data.last,
      first: response.data.first,
      empty: response.data.empty,
      pageNumber: response.data.pageNumber,
      perPage: response.data.perPage,
    });
  }

  function searchUser(event) {
    const value = event.target.value;
    setPage(0);
    setSearchValue(value);
    if (value.length < 3) {
      setUrl(`${COMMUNITY_USER}?userStatus=${userStatus}&page=${page}`);
    }
  }

  return (
    <>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0 pt-5">
            <div className="d-flex justify-content-between">
              <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                User List
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              {/* <Col sm="12" md="6" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <select
                      id="podcastStatusTable"
                      className="form-control border w-100 p-2"
                      onChange={(e) => {
                        setPage(0);
                        setSearchValue("");
                        setUserStatus(e.target.value);
                      }}
                    >
                      <option value={"All"}>All User</option>
                      <option value={"Active"}>Active User</option>
                      <option value={"DELETED"}>Deleted User</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col> */}
              <Col sm="12" md="12" className="ml-auto px-0">
                <FormGroup className="px-2">
                  <InputGroup className="input-group-alternative">
                    <Input
                      id="podcastSearchField"
                      className="form-control-alternative border"
                      placeholder="Search..."
                      type="text"
                      name="title"
                      value={searchValue}
                      onChange={searchUser}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <UserTableComponent
              userList={userList}
              userCount={
                userPaginationData?.pageNumber * userPaginationData?.perPage
              }
              setPage={setPage}
            />
          </CardBody>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <TablePagination
                paginationData={userPaginationData}
                page={page}
                setPage={setPage}
              />
            </nav>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
