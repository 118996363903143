import { get } from "axios/apiCall";
import { COMMUNITY_PODCAST } from "axios/apiUrl";
import { COMMUNITY_PODCAST_PUBLISHED } from "axios/apiUrl";
import {
  COMMUNITY_PODCAST_SEARCH,
  COMMUNITY_PODCAST_UNPUBLISHED,
  COMMUNITY_PODCAST_DELETED,
} from "axios/apiUrl";
import TablePagination from "components/common/table/TablePagination";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import PodcastTableComponent from "./table/PodcastTableComponent";

function PublishedPodcastTable({ savePodcast, page, setPage }) {
  const [podcastList, setPodcastList] = useState();
  const [podcastPaginationData, setPodcastPaginationData] = useState();

  const [podcastStatus, setPodcastStatus] = useState("published");

  const [url, setUrl] = useState(`${COMMUNITY_PODCAST_PUBLISHED}?page=`);

  useEffect(() => {
    get(`${url}${page}`, podcastSuccess);
  }, [page, url]);

  useEffect(() => {
    setUrl(`${COMMUNITY_PODCAST}/${podcastStatus}?page=`);
  }, [podcastStatus]);

  function podcastSuccess(response) {
    setPodcastList(response.data.data);
    setPodcastPaginationData({
      totalData: response.data.totalElements,
      totalPages: response.data.totalPages,
      numberOfData: response.data.numberOfElements,
      last: response.data.last,
      first: response.data.first,
      empty: response.data.empty,
      pageNumber: response.data.pageNumber,
      perPage: response.data.perPage,
    });
  }

  function searchPodcast(event) {
    const value = event.target.value;
    setPage(0);
    if (value.length > 2) {
      setUrl(
        `${COMMUNITY_PODCAST_SEARCH}?searchValue=${value}&podcastStatus=${podcastStatus}&page=`
      );
    } else {
      setUrl(
        podcastStatus === "published"
          ? `${COMMUNITY_PODCAST_PUBLISHED}?page=`
          : podcastStatus === "unpublished"
          ? `${COMMUNITY_PODCAST_UNPUBLISHED}?page=`
          : `${COMMUNITY_PODCAST_DELETED}?page=`
      );
    }
  }

  function changeTableData(event) {
    document.getElementById("podcastSearchField").value = "";
    setPodcastStatus(event.target.value.toLowerCase());
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 pt-5">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                    {podcastStatus} Podcast
                  </h3>

                  <Button
                    color="primary"
                    className="addBtnColor"
                    outline
                    type="button"
                    onClick={savePodcast}
                  >
                    Add Podcast
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12" md="6" className="ml-auto px-0">
                    <FormGroup className="px-2">
                      <InputGroup className="input-group-alternative">
                        <select
                          id="podcastStatusTable"
                          className="form-control border w-100 p-2"
                          onChange={changeTableData}
                        >
                          <option value={""} disabled>
                            Select Status
                          </option>
                          <option value={"PUBLISHED"}>Published Podcast</option>
                          <option value={"UNPUBLISHED"}>
                            Unpublished Podcast
                          </option>
                          <option value={"DELETED"}>Deleted Podcast</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6" className="ml-auto px-0">
                    <FormGroup className="px-2">
                      <InputGroup className="input-group-alternative">
                        <Input
                          id="podcastSearchField"
                          className="form-control-alternative border"
                          placeholder="Search..."
                          type="text"
                          name="title"
                          onChange={searchPodcast}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <PodcastTableComponent
                  podcastList={podcastList}
                  podcastCount={
                    podcastPaginationData?.pageNumber *
                    podcastPaginationData?.perPage
                  }
                  setPage={setPage}
                  podcastStatus={podcastStatus}
                />
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <TablePagination
                    paginationData={podcastPaginationData}
                    page={page}
                    setPage={setPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PublishedPodcastTable;
