import { get } from "axios/apiCall";
import { COMMUNITY_REPORTED_POST_COUNT } from "axios/apiUrl";
import Header from "components/Headers/Header";
import ReportPostTable from "components/ReportPost/ReportPostTable";
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

function ReportPost() {
  const [page, setPage] = useState(0);
  const [reportPostCountDetails, setReportPostCountDetails] = useState([]);

  useEffect(() => {
    get(COMMUNITY_REPORTED_POST_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    let list = [
      {
        title: "Todays Reported Post",
        count: response.todaysData,
      },
      {
        title: "This Month Reported Post",
        count: response.thisMonthData,
      },
      {
        title: "Active Reported Post",
        count: response.activeData,
      },
      {
        title: "Total Reported Post",
        count: response.totalData,
      },
    ];

    setReportPostCountDetails(list);
  }

  return (
    <>
      <Header data={reportPostCountDetails} />
      <Container className="mt--7" fluid>
        <Row>
          <ReportPostTable page={page} setPage={setPage} />
        </Row>
      </Container>
    </>
  );
}

export default ReportPost;
