import paths from "variables/paths";

let authMenuRoutes = [
  {
    path: paths.authLogin,
    name: "Login",
    icon: "ni ni-key-25",
    layout: paths.auth,
  },
  // {
  //   path: paths.authRegister,
  //   name: "Register",
  //   icon: "ni ni-circle-08",
  //   layout: paths.auth,
  // },
];

export default authMenuRoutes;
