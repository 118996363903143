import { get } from "axios/apiCall";
import { COMMUNITY_PODCAST_REQUEST } from "axios/apiUrl";
import TablePagination from "components/common/table/TablePagination";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader, Col,
  Container, FormGroup, Input, InputGroup,
  Row,
} from "reactstrap";
import PodcastRequestTableComponent from "./table/PodcastRequestTableComponent";

function PodcastRequestTable({ page, setPage }) {
  const [podcastRequestList, setPodcastRequestList] = useState();
  const [podcastPaginationData, setPodcastPaginationData] = useState();
  const [podcastRequestStatus, setPodcastRequestStatus] = useState("");

  const [url, setUrl] = useState(`${COMMUNITY_PODCAST_REQUEST}?page=`);

  useEffect(() => {
    get(`${url}${page}`, podcastRequestSuccess);
  }, [page, url]);

  useEffect(() => {
    setPage(0);
    setUrl(`${COMMUNITY_PODCAST_REQUEST}?status=${podcastRequestStatus}&page=`);
  }, [podcastRequestStatus]);

  function podcastRequestSuccess(response) {
    setPodcastRequestList(response.data.data);
    setPodcastPaginationData({
      totalData: response.data.totalElements,
      totalPages: response.data.totalPages,
      numberOfData: response.data.numberOfElements,
      last: response.data.last,
      first: response.data.first,
      empty: response.data.empty,
      pageNumber: response.data.pageNumber,
      perPage: response.data.perPage,
    });
  }

  function searchPodcastRequest(event) {
    setPage(0);
    const value = event.target.value;
    if (value.length > 2) {
      setUrl(
        `${COMMUNITY_PODCAST_REQUEST}?searchValue=${value}&status=${podcastRequestStatus}&page=`
      );
    } else {
      setUrl(`${COMMUNITY_PODCAST_REQUEST}?status=${podcastRequestStatus}&page=`);
    }
  }

  function changeTableData(event) {
    document.getElementById("podcastSearchField").value = "";
    setPodcastRequestStatus(event.target.value.toLowerCase());
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 pt-5">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                    Podcast Request List
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12" md="6" className="ml-auto px-0">
                    <FormGroup className="px-2">
                      <InputGroup className="input-group-alternative">
                        <select
                          id="podcastStatusTable"
                          className="form-control border w-100 p-2"
                          onChange={changeTableData}
                          defaultValue={""}
                        >
                          <option value={""}>
                            All Request
                          </option>
                          <option value={"ACCEPTED"}>Accepted Request</option>
                          <option value={"PENDING"}>
                            Pending Request
                          </option>
                          <option value={"REJECTED"}>Rejected Request</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6" className="ml-auto px-0">
                    <FormGroup className="px-2">
                      <InputGroup className="input-group-alternative">
                        <Input
                          id="podcastSearchField"
                          className="form-control-alternative border"
                          placeholder="Search..."
                          type="text"
                          name="title"
                          onChange={searchPodcastRequest}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <PodcastRequestTableComponent
                  podcastRequestList={podcastRequestList}
                  podcastCount={
                    podcastPaginationData?.pageNumber *
                    podcastPaginationData?.perPage
                  }
                  setPage={setPage}
                />
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <TablePagination
                    paginationData={podcastPaginationData}
                    page={page}
                    setPage={setPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PodcastRequestTable;
