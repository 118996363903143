import Swal from "sweetalert2";

export function confirmDeleteAlert(deleteFunction) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteFunction();
    }
  });
}

export function customSwalAlert(title, icon) {
  Swal.fire({
    title: title,
    icon: icon,
    timer: 1500,
    confirmButtonColor: "#3085d6",
  });
}
