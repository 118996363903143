import axios from "axios";
import { removeCookies, setCookies } from "login/authCookie";
import { getCookies } from "login/authCookie";
import { logoutHelper } from "login/loginHelper";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  ACCESS_TOKEN_PREFIX,
  REFRESH_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_PREFIX,
} from "../login/authCookieType";
import { COMMUNITY_ADMIN_LOGIN_ACCESS_TOKEN } from "./apiUrl";

const client = axios.create();

// client.defaults.headers.common["ecommunity"] =
//   ACCESS_TOKEN_PREFIX + getCookies(ACCESS_TOKEN_COOKIE_NAME);

client.interceptors.request.use(
  async (request) => {
    const accessToken = getCookies(ACCESS_TOKEN_COOKIE_NAME);
    if (accessToken) {
      request.headers["ecommunity"] = ACCESS_TOKEN_PREFIX + accessToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response) {
      const originalRequest = error?.config;
      const { data: errorResponse } = error?.response;
      if (
        (errorResponse.status === 401 || error.response.status === 401) &&
        originalRequest.url === COMMUNITY_ADMIN_LOGIN_ACCESS_TOKEN
      ) {
        window.location.href = "/auth/login";
        removeCookies(ACCESS_TOKEN_COOKIE_NAME);
        removeCookies(REFRESH_TOKEN_COOKIE_NAME);
        return Promise.reject(error);
      }
      if (
        (errorResponse.status === 401 || error.response.status === 401 ||
          errorResponse.message === "Expired token") &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        return axios
          .get(COMMUNITY_ADMIN_LOGIN_ACCESS_TOKEN, {
            headers: {
              ecommunityrefreshtoken:
                REFRESH_TOKEN_PREFIX + getCookies(REFRESH_TOKEN_COOKIE_NAME),
            },
          })
          .then((res) => {
            const { data: response } = res;
            if (response.status === 200) {
              setCookies(ACCESS_TOKEN_COOKIE_NAME, response.access_token.token);
              client.defaults.headers.common["ecommunity"] =
                ACCESS_TOKEN_PREFIX + response.access_token.token;

              return client(originalRequest);
            } else {
              logoutHelper();
              return Promise.reject(error);
            }
          })
          .catch((err) => {
            logoutHelper();
            return Promise.reject(error);
          });
      } else if (errorResponse.status === 401 || error.response.status === 401) {
        logoutHelper();
      }
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

// All request will wait 2 seconds before timeout
// client.defaults.timeout = process.env.NEXT_PUBLIC_DEFAULT_TIMEOUT_PER_REQUEST;
//client.defaults.withCredentials = true;

export default client;
