import React, { useState } from "react";

export default function ShowPostImageComponent({
  fileList,
  setFileList
}) {
  const [files, setFiles] = useState(fileList);

  const deleteImage = (file) => {
    const newImageList = files.filter((item) => file.id !== item.id);
    setFiles(newImageList);
    if (setFileList) {
      setFileList(newImageList);
    }
  };
  return (
    <>
      <div className="row">
        {files?.map((file, index) => (
          <div className="col-6 col-md-4 col-lg-3 my-2 px-0 px-2" key={index}>
            {file.fileType.toLowerCase() === 'image' ? (
              <img
                src={file.fileURL}
                alt="post"
                className="img-thumbnail rounded w-100 h-100"
                style={{ minHeight: "120px" }}
              />
            ) : (
              <video
                controls
                style={{ width: '100%',height: '100%' }}
                className="img-thumbnail rounded w-100 h-100"
              >
                <source src={file.fileURL} type="video/mp4"/>
                    Your browser does not support the video tag.
              </video>
            )}

            {setFileList && (
              <div
                className="d-flex justify-content-end"
                style={{ position: "absolute", top: "4px", right: "10px" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    border: "1px solid #d5d5d5",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteImage(file)}
                >
                  <i className="ni ni-fat-delete"></i>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
