import ShowImageComponent from "components/common/image/ShowImageComponent";
import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";

function ViewResourceDetails({ resourceData, setToggle }) {
  return (
    <>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h3
            className="modal-title align-self-center"
            id="modal-title-default"
          >
            Reshare Details
          </h3>
          <button
            aria-label="Close"
            className="close m-0"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </div>
      <div className="modal-body pt-0">
        {resourceData && resourceData.images && (
          <ShowImageComponent imageList={resourceData.images} />
        )}
        <hr />
        <Row>
          <Col lg="3" md="4" sm="6">
            <h3>Reshare Created By</h3>
            <p>{resourceData.name}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Status</h3>
            <p>{resourceData.resourceStatus}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Created Date</h3>
            <p>{moment(resourceData.createdAt).format("MMM DD, YYYY")}</p>
          </Col>
          {/* <Col lg="3" md="4" sm="6">
            <h3>Updated Date</h3>
            <p>{moment(resourceData.updateAt).format("MMM DD, YYYY")}</p>
          </Col> */}
          <Col lg="3" md="4" sm="6">
            <h3>Total Comments</h3>
            <p>{resourceData.commentsCount}</p>
          </Col>
          <Col sm="12">
            <h3>Reshare Content</h3>
            <p style={{ wordWrap: "break-word" }}>
              {resourceData.description && resourceData.description !== ""
                ? resourceData.description
                : "No Description"}
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ViewResourceDetails;
