import paths from "variables/paths";
import Dashboard from "views/Dashboard.js";
import Login from "views/pages/Login.js";
import User from "views/pages/User";
import Podcast from "views/pages/Podcast";
import Post from "views/pages/Post";
import Resource from "views/pages/Resource";
import ReportType from "views/pages/ReportType";
import ReportPost from "../views/pages/ReportPost";
import PodcastRequest from "../views/pages/PodcastRequest";

const sideBarRoutes = [
  {
    path: paths.adminDashboard,
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Dashboard,
    layout: paths.admin,
  },
  {
    path: paths.adminUser,
    name: "Community User",
    icon: "ni ni-single-02",
    component: User,
    layout: paths.admin,
  },
  {
    path: paths.adminPost,
    name: "Community Post",
    icon: "fas fa-file-image",
    component: Post,
    layout: paths.admin,
  },
  {
    path: paths.adminReportPost,
    name: "Report Post",
    icon: "ni ni-single-copy-04",
    component: ReportPost,
    layout: paths.admin,
  },
  {
    path: paths.adminReportType,
    name: "Report Type",
    icon: "fas fa-flag",
    component: ReportType,
    layout: paths.admin,
  },
  {
    path: paths.adminResource,
    name: "Reshare",
    icon: "fas fa-share-alt",
    component: Resource,
    layout: paths.admin,
  },
  {
    path: paths.adminPodcast,
    name: "Podcast List",
    icon: "ni ni-note-03",
    component: Podcast,
    layout: paths.admin,
  },
  {
    path: paths.adminPodcastRequest,
    name: "Podcast Request",
    icon: "fas fa-flag",
    component: PodcastRequest,
    layout: paths.admin,
  },
  {
    path: paths.authLogin,
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: paths.auth,
  },
];

export default sideBarRoutes;
