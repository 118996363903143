import { deleted } from "axios/apiCall";
import CustomModal from "components/modal/CustomModal";
import { customSwalAlert } from "components/alert/CustomAlert";
import { confirmDeleteAlert } from "components/alert/CustomAlert";
import React, { useEffect, useState } from "react";
import ViewUserDetails from "../ViewUserDetails";
import { COMMUNITY_USER_DELETE } from "axios/apiUrl";

export default function UserTableRowComponent({ user, index, setPage }) {
  const [toggleViewModal, setToggleViewModal] = useState(false);
  const [userStatus, setUserStatus] = useState(user.status);

  useEffect(() => {
    setUserStatus(user.status);
  }, [user]);

  function deleteUser() {
    deleted(
      `${COMMUNITY_USER_DELETE}/${user.username}`,
      userDeleteSuccess,
      userDeleteError
    );
  }

  function userDeleteSuccess(response) {
    if (response.status === 200) {
      customSwalAlert("User Deleted!!", "success");
      setUserStatus("Deleted");
    } else {
      customSwalAlert(response.message, "error");
    }
  }

  function userDeleteError(error) {
    console.log("Error: ", error);
  }

  function viewUser(e) {
    if (e.target.cellIndex < 5) {
      setToggleViewModal(true);
    }
  }
  return (
    <>
      <tr
        style={{ cursor: "pointer" }}
        onClick={viewUser}
        className={index % 2 !== 0 ? "oddRowStyle" : ""}
      >
        <th>{index.toString()}</th>
        <th scope="row">
          <img
            alt="..."
            className="rounded-circle"
            src={user.photoUrl}
            width="50"
            height="50"
          />
        </th>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{userStatus.toUpperCase()}</td>
        <td style={{ cursor: "default" }}>
          <div className="d-flex justify-content-end">
            <i
              className="fas fa-trash px-2"
              style={{
                fontSize: "1.2rem",
                cursor: `${
                  userStatus.toLowerCase() === "Active".toLowerCase() &&
                  "pointer"
                }`,
                color: `${
                  userStatus.toLowerCase() === "Active".toLowerCase()
                    ? "#f5365c82"
                    : "rgb(245 54 92 / 17%)"
                }`,
              }}
              onClick={() =>
                userStatus.toLowerCase() === "Active".toLowerCase() &&
                confirmDeleteAlert(deleteUser)
              }
            ></i>
          </div>
        </td>
      </tr>
      {toggleViewModal && (
        <CustomModal
          toggle={toggleViewModal}
          setToggle={setToggleViewModal}
          size={"lg"}
        >
          <ViewUserDetails
            user={user}
            setToggle={setToggleViewModal}
            setPage={setPage}
          />
        </CustomModal>
      )}
    </>
  );
}
