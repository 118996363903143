import React, { useState } from "react";

export default function ShowImageComponent({
  imageList,
  setImageList,
  type = "personal",
}) {
  const [image, setImage] = useState(imageList);

  const deleteImage = (img) => {
    const newImageList = image.filter((item) => img !== item);
    setImage(newImageList);
    if (setImageList) {
      setImageList(newImageList);
    }
  };
  return (
    <>
      {/* <div className="d-flex justify-content-center my-3"> */}
      <div className="row">
        {image?.map((img, index) => (
          <div className="col-3 my-2 px-0 px-2" key={index}>
            <img
              src={img}
              alt="post"
              className="img-thumbnail rounded w-100 h-100"
              style={{ maxHeight: "120px" }}
            />
            {setImageList && (
              <div
                className="d-flex justify-content-end"
                style={{ position: "absolute", top: "4px", right: "10px" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    border: "1px solid #d5d5d5",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteImage(img)}
                >
                  <i className="ni ni-fat-delete"></i>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {/* </div> */}
    </>
  );
}
