import {put} from "axios/apiCall";
import {COMMUNITY_ACCEPT_PODCAST_REQUEST} from "axios/apiUrl";
import {COMMUNITY_BASE_URL} from "axios/apiUrl";
import moment from "moment";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";
import {AcceptPodcastRequestModal} from "./AcceptPodcastRequestModal";
import CustomModal from "../modal/CustomModal";

function ViewPodcastRequestDetails({podcastRequestDetails, setToggle, setPage, setAcceptRequestModalOpen}) {
    const {
        audioFileLink: fileUrl,
        imageLink: imageUrl,
        previousPodcastLink: previousLink,
        status
    } = podcastRequestDetails;

    const [statusDropdown, setStatusDropdown] = useState(false);

    function acceptRequestFunction() {
        setToggle(false);
        setAcceptRequestModalOpen(true);
        // if (statusDropdown === false) {
        //     setStatusDropdown(true);
        // } else {
        //     const status = document.getElementById("podcastStatus").value;
        //     put(
        //         `${COMMUNITY_ACCEPT_PODCAST_REQUEST}/${podcastRequestDetails.id}?status=${status}`,
        //         {},
        //         statusChangeSuccess
        //     );
        // }
    }

    function statusChangeSuccess(response) {
        if (response.success) {
            setPage(1);
            setPage(0);
            setToggle(false);
            toast.success(`Podcast request Status Changed`, {
                theme: "colored",
            });
        } else {
            toast.error(response.message, {
                theme: "colored",
            });
        }
    }

    return (
        <>
            <div className="modal-header">
                <div className="w-100 d-flex justify-content-between">
                    <h3
                        className="modal-title align-self-center"
                        id="modal-title-default"
                    >
                        Podcast Request Details
                    </h3>
                    <button
                        aria-label="Close"
                        className="close m-0"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setToggle(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            </div>
            <div className="modal-body pt-0">
                {imageUrl && (
                    <div className="d-flex justify-content-center">
                        <img
                            src={`${COMMUNITY_BASE_URL}/images/podcast/images/${imageUrl}`}
                            alt="..."
                            style={{maxWidth: "8rem", height: "8rem"}}
                            className="img-thumbnail rounded-circle"
                        />
                    </div>
                )}
                <hr/>
                <Row style={{paddingBottom: 8}}>
                    <Col sm={12} style={{paddingBottom: 8}}>
                        <h3>Details</h3>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Title</h3>
                        <p>{podcastRequestDetails?.podcastTitle}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Author</h3>
                        <p>{podcastRequestDetails?.podcastAuthor}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Author Email</h3>
                        <p>{podcastRequestDetails?.email}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Lifestyle</h3>
                        <p>{podcastRequestDetails?.lifestyle}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Status</h3>
                        <p>{podcastRequestDetails.status}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} style={{paddingBottom: 8}}>
                        <h3>Uploader Information</h3>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Uploaded By</h3>
                        <p>{podcastRequestDetails.uploadedBy}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Uploader Email</h3>
                        <p>{podcastRequestDetails.uploadedByEmail}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Uploader Username</h3>
                        <p>{podcastRequestDetails.uploadedByUsername}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Created Date</h3>
                        <p>{moment(podcastRequestDetails?.createAt).format("MMM DD, YYYY")}</p>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <h3>Updated Date</h3>
                        <p>{moment(podcastRequestDetails?.updateAt).format("MMM DD, YYYY")}</p>
                    </Col>

                    <Col sm="12" style={{paddingBottom: 10, paddingTop: 10}}>
                        <h3>About Podcast</h3>
                        <p>{podcastRequestDetails?.podcastDescription}</p>
                    </Col>
                    <Col sm="12">
                        <h3>Message</h3>
                        <p>{podcastRequestDetails?.message}</p>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer d-flex justify-content-between align-items-end">
                <Row>
                    {fileUrl && (
                        <Col>
                            <h3>Podcast File</h3>
                            <audio controls>
                                <source
                                    src={`${COMMUNITY_BASE_URL}/images/podcast/files/${fileUrl}`}
                                    className="w-100"
                                    type="audio/ogg"
                                />
                                Your browser does not support the audio element.
                            </audio>
                        </Col>
                    )}
                </Row>
                {status.toLowerCase() === "pending" ? (
                    <Row>
                        {/*{statusDropdown ? (*/}
                        {/*    <Col md="8">*/}
                        {/*        <Form>*/}
                        {/*            <FormGroup>*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label htmlFor="podcastStatus">State</label>*/}
                        {/*                    <select*/}
                        {/*                        id="podcastStatus"*/}
                        {/*                        className="form-control"*/}
                        {/*                        selected={status.toUpperCase()}*/}
                        {/*                    >*/}
                        {/*                        <option value={""} disabled>*/}
                        {/*                            Select Status*/}
                        {/*                        </option>*/}
                        {/*                        <option value={"ACCEPTED"}>Accept</option>*/}
                        {/*                        <option value={"REJECTED"}>Reject</option>*/}
                        {/*                    </select>*/}
                        {/*                </div>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Form>*/}
                        {/*    </Col>*/}
                        {/*) : (*/}
                        {/*    ""*/}
                        {/*)}*/}
                        <Col
                            sm="auto"
                            className="d-flex justify-content-end align-self-center pt-2 px-md-0"
                        >
                            <Button
                                color="primary"
                                className=""
                                type="button"
                                onClick={acceptRequestFunction}
                            >
                                Accept / Reject Request
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}

export default ViewPodcastRequestDetails;

