import { UserActionType } from "../Action-Type";

export const setUserInfo = (userInfo) => {
  return {
    type: UserActionType.SET_USER_INFO,
    payload: userInfo,
  };
};

export const setIsAuthenticated = (isAuthenticated) => {
  return {
    type: UserActionType.SET_IS_AUTHENTICATED,
    payload: isAuthenticated,
  };
};
