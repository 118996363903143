import { UserActionType } from "../Action-Type";

export const initialState = {
  userInfo: null,
  isAuthenticated: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UserActionType.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case UserActionType.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    default:
      return state;
  }
}
