import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import ShowPostImageComponent from "../common/image/ShowPostImageComponent";

function ViewPostDetails({ postData, setToggle }) {
  return (
    <>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h3
            className="modal-title align-self-center"
            id="modal-title-default"
          >
            Post Details
          </h3>
          <button
            aria-label="Close"
            className="close m-0"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </div>
      <div className="modal-body pt-0">
        {postData && postData.files && (
          <ShowPostImageComponent fileList={postData.files} />
        )}
        <hr />
        {postData && postData.type === "Shared" && (
          <>
            <Row>
              <Col lg="3" md="4" sm="6">
                <h3>Shared By</h3>
                <p>{postData.sharedUserName}</p>
              </Col>
              <Col sm="12">
                <h3>Share Content</h3>
                <p style={{ wordWrap: "break-word" }}>
                  {postData.sharedUserName}
                </p>
              </Col>
            </Row>
            <hr />
          </>
        )}
        <Row>
          <Col lg="3" md="4" sm="6">
            <h3>Post Created By</h3>
            <p>{postData.name}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Status</h3>
            <p>{postData.postStatus}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Created Date</h3>
            <p>{moment(postData.createdAt).format("MMM DD, YYYY")}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Total Comments</h3>
            <p>{postData.commentsCount}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Total Likes</h3>
            <p>{postData.likeCount}</p>
          </Col>
          <Col lg="3" md="4" sm="6">
            <h3>Total Share</h3>
            <p>{postData.shareCount}</p>
          </Col>
          <Col sm="12">
            <h3>Post Content</h3>
            <p style={{ wordWrap: "break-word" }}>{postData.description}</p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ViewPostDetails;
