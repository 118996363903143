import React from "react";
import { Table } from "reactstrap";
import PodcastTableRowComponent from "./PodcastTableRowComponent";

function PodcastTableComponent({
  podcastList,
  podcastCount,
  setPage,
  podcastStatus,
}) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="tableHeaderStyle">
            <th scope="col">#</th>
            <th scope="col">Image</th>
            <th scope="col">Author</th>
            <th scope="col">Title</th>
            <th scope="col">Create Date</th>
            <th scope="col">
              <span className="d-flex justify-content-end">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {podcastList && podcastList?.length > 0 ? (
            podcastList?.map((podcast, index) => {
              return (
                <PodcastTableRowComponent
                  podcast={podcast}
                  index={index + podcastCount + 1}
                  key={podcast.id}
                  setPage={setPage}
                  podcastStatus={podcastStatus}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={7} style={{ textAlign: "center" }}>
                No Podcast
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default PodcastTableComponent;
