import React from "react";
import { Table } from "reactstrap";
import ReportTypeTableRowComponent from "./ReportTypeTableRowComponent";

function ReportTypeTableComponent({ reportTypeList }) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="tableHeaderStyle">
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">
              <span className="d-flex justify-content-center">Status</span>
            </th>
            <th scope="col">
              <span className="d-flex justify-content-end">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {reportTypeList && reportTypeList?.length > 0 ? (
            reportTypeList?.map((reportType, index) => (
              <ReportTypeTableRowComponent
                reportType={reportType}
                index={index + 1}
                key={reportType.id}
              />
            ))
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No Report Type
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ReportTypeTableComponent;
