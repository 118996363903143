import { put } from "axios/apiCall";
import { post } from "axios/apiCall";
import { COMMUNITY_PODCAST } from "axios/apiUrl";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoaderButtonComponent from "../common/LoaderButtonComponent";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";

function SaveUpdatePodcast({ setToggle, setPage, podcast }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (podcast) {
      document.getElementById(`imageUrlLabel`).innerHTML = podcast.imageUrl;
      document.getElementById(`fileUrlLabel`).innerHTML = podcast.fileUrl;
    }
  }, [podcast]);

  function selectedFile(event) {
    if (event.target.value !== "") {
      document.getElementById(`${event.target.id}Label`).innerHTML =
        document.getElementById(event.target.id).files[0].name;
    }
  }

  function saveUpdatePodcast(event) {
    event.preventDefault();
    const formData = new FormData();

    const data = event.target;

    const id = data.id.value;

    formData.append("id", id);
    formData.append("title", data.title.value);
    formData.append("image", document.getElementById("imageUrl").files[0]);
    formData.append("file", document.getElementById("fileUrl").files[0]);
    formData.append("description", data.description.value);
    formData.append("author", data.author.value);

    if (id !== "") {
      formData.append("imageUrl", podcast.imageUrl);
      formData.append("fileUrl", podcast.fileUrl);
      formData.append("status", podcast.status);
    }
    setLoading(true);

    if (id === "") {
      post(COMMUNITY_PODCAST, formData, savePodcastSuccess, savePodcastError);
    } else {
      put(COMMUNITY_PODCAST, formData, updatePodcastSuccess, savePodcastError);
    }
  }

  function savePodcastSuccess(response) {
    success(response, "Successfully Saved");
  }

  function updatePodcastSuccess(response) {
    success(response, "Successfully Updated");
  }

  function success(response, message) {
    setLoading(false);
    if (response.success) {
      setPage(1);
      setPage(0);
      setToggle(false);
      toast.success(message, {
        theme: "colored",
      });
    } else {
      toast.error(response.message, {
        theme: "colored",
      });
    }
  }

  function savePodcastError(error) {
    setLoading(false);
    console.log("SAVE UPDATE PODCAST: ", error);
  }

  return (
    <>
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>{podcast ? "Update" : "Save"} Podcast</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={saveUpdatePodcast}>
              <Input
                type="text"
                name="id"
                defaultValue={podcast ? podcast.id : ""}
                className="d-none"
              />
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    placeholder="Title"
                    type="text"
                    name="title"
                    defaultValue={podcast ? podcast.title : ""}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                      className="form-control-alternative"
                      placeholder="Author Name"
                      type="text"
                      name="author"
                      defaultValue={podcast ? podcast.author : ""}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="imageUrl"
                    lang="es"
                    name="imageUrl"
                    onChange={selectedFile}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="imageUrl"
                    id="imageUrlLabel"
                  >
                    Select Image
                  </label>
                </div>
              </FormGroup>

              <FormGroup>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="fileUrl"
                    lang="es"
                    name="fileUrl"
                    onChange={selectedFile}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="fileUrl"
                    id="fileUrlLabel"
                  >
                    Select File
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    placeholder="Description"
                    name="description"
                    defaultValue={podcast ? podcast.description : ""}
                    rows="3"
                    type="textarea"
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="info"
                  type="button"
                  onClick={() => setToggle(false)}
                >
                  Cancel
                </Button>
                <LoaderButtonComponent
                  text={"Save"}
                  loading={loading}
                  type={"submit"}
                />
                {/* <Button className="my-4" color="primary" type="submit">
                  Save
                </Button> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SaveUpdatePodcast;
