import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "reactstrap";
import UserLogoMenu from "./UserLogoMenu";

const AdminNavbar = (props) => {
  return (
    <>
      <Navbar
        className="navbar-top navbar-dark"
        expand="md"
        id="navbar-main"
        style={{ backgroundColor: "#016b4f" }}
      >
        <Container
          fluid
          className="d-flex justify-content-end justify-content-lg-between"
        >
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to={props.location.pathname}
          >
            {props.brandText}
          </Link>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UserLogoMenu />
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
