import { put } from "axios/apiCall";
import { post } from "axios/apiCall";
import { COMMUNITY_REPORT_TYPE } from "axios/apiUrl";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LoaderButtonComponent from "../common/LoaderButtonComponent";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { setIsReportTypeDataChanged } from "redux/report/ReportAction";
import { useDispatch } from "react-redux";

function SaveUpdateReportType({ setToggle, reportType }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const saveUpdateReportType = (event) => {
    event.preventDefault();
    const name = event.target.name.value;

    if (name === "") {
      toast.error("Enter something for report type", { theme: "colored" });
    } else {
      setLoading(true);
      if (reportType && reportType.id) {
        put(
          `${COMMUNITY_REPORT_TYPE}/${reportType.id}`,
          { name },
          reportTypeSaveSuccess,
          reportTypeSaveError
        );
      } else {
        post(
          COMMUNITY_REPORT_TYPE,
          { name },
          reportTypeSaveSuccess,
          reportTypeSaveError
        );
      }
    }
  };

  const reportTypeSaveSuccess = (response) => {
    setLoading(false);
    if (response.success) {
      setToggle(false);
      dispatch(setIsReportTypeDataChanged());
      toast.success(response.message, { theme: "colored" });
    } else {
      toast.error(response.message, {
        theme: "colored",
      });
    }
  };

  const reportTypeSaveError = (error) => {
    setLoading(false);
    toast.error("Something wrong. please try again later!!", {
      theme: "colored",
    });
  };

  return (
    <>
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>{reportType ? "Update" : "Save"} Report Type</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={saveUpdateReportType}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="form-control-alternative"
                    placeholder="Name"
                    type="text"
                    name="name"
                    defaultValue={reportType ? reportType.name : ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="info"
                  type="button"
                  onClick={() => setToggle(false)}
                >
                  Cancel
                </Button>
                <LoaderButtonComponent
                  text={"Save"}
                  loading={loading}
                  type={"submit"}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SaveUpdateReportType;
