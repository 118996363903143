import { toast } from "react-toastify";
import client from "./client";

export const get = async (
  url,
  successFunction = () => {},
  errorFunction = () => {}
) => {
  try {
    const res = await client.get(url);
    successFunction(res.data);
  } catch (err) {
    errorFunction(err.response);
  }
};

export const post = async (
  url,
  body,
  successFunction = () => {},
  errorFunction = () => {}
) => {
  try {
    const res = await client.post(url, body);
    successFunction(res.data);
  } catch (err) {
    errorFunction(err.response);
  }
};

export const put = async (
  url,
  body,
  successFunction = () => {},
  errorFunction = () => {}
) => {
  try {
    const res = await client.put(url, body);
    successFunction(res.data);
  } catch (err) {
    toast.error(err.message, {
      theme: "colored",
    });
    errorFunction(err.response);
  }
};

export const patch = async (
  url,
  successFunction = () => {},
  errorFunction = () => {}
) => {
  try {
    const res = await client.patch(url);
    successFunction(res.data);
  } catch (err) {
    toast.error(err.message, {
      theme: "colored",
    });
    errorFunction(err.response);
  }
};

export const deleted = async (
  url,
  successFunction = () => {},
  errorFunction = () => {}
) => {
  try {
    const res = await client.delete(url);
    successFunction(res.data);
  } catch (err) {
    errorFunction(err.response);
  }
};
