import { get } from "axios/apiCall";
import { COMMUNITY_POST_COUNT } from "axios/apiUrl";
import Header from "components/Headers/Header";
import CustomModal from "components/modal/CustomModal";
import PostTable from "components/post/PostTable";
import SaveUpdatePost from "components/post/SaveUpdatePost";
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

function Post() {
  const [saveModalToggle, setSaveModalToggle] = useState(false);
  const [page, setPage] = useState(0);
  const [postCountDetails, setPostCountDetails] = useState([]);

  useEffect(() => {
    get(COMMUNITY_POST_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    let list = [
      {
        title: "Todays Post",
        count: response.todaysData,
      },
      {
        title: "This Month Post",
        count: response.thisMonthData,
      },
      {
        title: "Active Post",
        count: response.activeData,
      },
      {
        title: "Total Post",
        count: response.totalData,
      },
    ];

    setPostCountDetails(list);
  }

  return (
    <>
      <Header data={postCountDetails} />
      <Container className="mt--7" fluid>
        <Row>
          <PostTable
            page={page}
            setPage={setPage}
            setSaveModalToggle={setSaveModalToggle}
          />
        </Row>
      </Container>
      {saveModalToggle ? (
        <CustomModal toggle={saveModalToggle} setToggle={setSaveModalToggle}>
          <SaveUpdatePost setToggle={setSaveModalToggle} setPage={setPage} />
        </CustomModal>
      ) : (
        ""
      )}
    </>
  );
}

export default Post;
