import React from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

function SidebarSingleItem({ prop }) {
  return (
    <>
      <NavItem>
        <NavLink
          to={prop.path}
          tag={NavLinkRRD}
          onClick={prop.closeCollapse}
          activeClassName="active"
        >
          <i className={prop.icon} />
          {prop.name}
        </NavLink>
      </NavItem>
    </>
  );
}

export default SidebarSingleItem;
