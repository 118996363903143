import { get } from "axios/apiCall";
import { COMMUNITY_REPORT_TYPE_ALL } from "axios/apiUrl";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  InputGroup,
  Row,
} from "reactstrap";
import ReportTypeTableComponent from "./table/ReportTypeTableComponent";

function ReportTypeTable({ saveReportType }) {
  const { isReportTypeDataChanged } = useSelector(
    (state) => state.reportReducer
  );
  const [reportTypeList, setReportTypeList] = useState([]);
  const [reportTypeStatus, setReportTypeStatus] = useState("Active");

  const [reportTypeListUrl, setReportTypeListUrl] = useState(
    `${COMMUNITY_REPORT_TYPE_ALL}?status=ALL`
  );

  useEffect(() => {
    setReportTypeListUrl(
      `${COMMUNITY_REPORT_TYPE_ALL}?status=${reportTypeStatus}`
    );
  }, [reportTypeStatus]);

  useEffect(() => {
    get(reportTypeListUrl, reportTypeSuccess);
  }, [isReportTypeDataChanged, reportTypeListUrl]);

  const reportTypeSuccess = (response) => {
    if (response.success) {
      setReportTypeList(response.data);
    } else {
      setReportTypeList([]);
    }
  };

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 pt-5">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0" style={{ textTransform: "capitalize" }}>
                    Report Type
                  </h3>

                  <Button
                    color="primary"
                    className="addBtnColor"
                    outline
                    type="button"
                    onClick={saveReportType}
                  >
                    Add Report Type
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {/* <Row>
                  <Col sm="12" className="ml-auto px-0">
                    <FormGroup className="px-2">
                      <InputGroup className="input-group-alternative">
                        <select
                          className="form-control border w-100 p-2"
                          value={reportTypeStatus}
                          onChange={(e) => {
                            setReportTypeStatus(e.target.value);
                          }}
                        >
                          <option value={"ALL"}>All Report Type</option>
                          <option value={"ACTIVE"}>Active Report Type</option>
                          <option value={"DELETED"}>Deleted Report Type</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row> */}
                <ReportTypeTableComponent reportTypeList={reportTypeList} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ReportTypeTable;
