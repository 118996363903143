import React from "react";
import CardCountItem from "./CardCountItem";

export default function CardCountList({ data }) {
  return (
    <>
      {data?.map((item, index) => {
        return <CardCountItem key={index} item={item} />;
      })}
    </>
  );
}
