import CustomModal from "components/modal/CustomModal";
import ReportTypeTable from "components/ReportType/ReportTypeTable";
import SaveUpdateReportType from "components/ReportType/SaveUpdateReportType";
import React, { useState } from "react";
import Header from "../../components/Headers/Header";

function ReportType() {
  const [saveModalToggal, setSaveModalToggal] = useState(false);

  function saveReportType() {
    setSaveModalToggal(true);
  }

  return (
    <>
      <Header />
      <ReportTypeTable saveReportType={saveReportType} />

      {saveModalToggal ? (
        <CustomModal toggle={saveModalToggal} setToggle={setSaveModalToggal}>
          <SaveUpdateReportType setToggle={setSaveModalToggal} />
        </CustomModal>
      ) : (
        ""
      )}
    </>
  );
}

export default ReportType;
