import React from "react";
import { Table } from "reactstrap";
import ResourceTableRowComponent from "./ResourceTableRowComponent";

export default function ResourceTableComponent({
  resourceList,
  resourceCount,
  setPage,
}) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="tableHeaderStyle">
            <th scope="col">#</th>
            <th scope="col">Created By</th>
            <th scope="col">Created At</th>
            <th scope="col">Zip Code</th>
            <th scope="col">Status</th>
            <th scope="col">
              <span className="d-flex justify-content-end">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {resourceList && resourceList?.length > 0 ? (
            resourceList?.map((resource, index) => {
              return (
                <ResourceTableRowComponent
                  resource={resource}
                  index={index + resourceCount + 1}
                  key={index}
                  setPage={setPage}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No Resource
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
