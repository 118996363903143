import React, { useEffect, useState } from "react";
import "../../assets/style/table.css";
import Header from "components/Headers/Header";
import { get } from "axios/apiCall";
import {COMMUNITY_PODCAST_REQUEST_COUNT} from "axios/apiUrl";
import PodcastRequestTable from "../../components/podcast-request/PodcastRequestTable";

function PodcastRequest() {
  const [page, setPage] = useState(0);

  const [podcastRequestCount, setPodcastRequestCount] = useState([]);

  useEffect(() => {
    get(COMMUNITY_PODCAST_REQUEST_COUNT, countSuccess);
  }, []);

  function countSuccess(response) {
    if (response.success) {
      let list = [
        {
          title: "Accepted Request",
          count: response.data.acceptedPodcastRequest,
        },
        {
          title: "Pending Request",
          count: response.data.pendingPodcastRequest,
        },
        {
          title: "Rejected Request",
          count: response.data.rejectedPodcastRequest,
        },
        {
          title: "Total Request",
          count: response.data.totalPodcastRequest,
        },
      ];
      setPodcastRequestCount(list);
    }
  }

  return (
    <>
      <Header
        data={podcastRequestCount}
      />
      <PodcastRequestTable
        page={page}
        setPage={setPage}
      />
    </>
  );
}

export default PodcastRequest;
